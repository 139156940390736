import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Judge/Dashboard.js';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';
import { checkInJudge, getMyAssignments } from '../../../utils/API.js';
import 'chart.js/auto';
import { Typography, Divider, Button } from '@mui/material';
import { useAuth } from '../../../utils/AuthContext.js';
import useFeedback from '../../../utils/FeedbackContext/useFeedback.js';

// Check in for Senior Design Day
const CheckIn = () => {
  const { user } = useAuth();
  const { notifySuccess, notifyError } = useFeedback();
  const checkedIn = user?.checkedIn;
  const checkIn = async () => {
    try {
      await checkInJudge();
      notifySuccess('Checked In Successfully');

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      notifyError(error?.response?.data?.message) ||
        notifyError('Check In Failed');
    }
  };
  return (
    <>
      <Typography {...StylesForm.text('h6')}>
        Senior Design Day Check In
      </Typography>
      <Divider />
      <Typography {...StylesForm.text('p')}>
        Check in for Senior Design Day here. Please make sure to check in on the
        day of the event.
      </Typography>
      <Button variant="contained" disabled={checkedIn} onClick={checkIn}>
        {checkedIn ? 'Checked In (Thanks)' : 'Check In'}
      </Button>
    </>
  );
};

// Widget for Judge Assignments Count
const InformAssignments = ({ assignments, status }) => {
  function filter(type) {
    return assignments.filter((a) => a?.rubricCopy?.type === type) || [];
  }
  return (
    assignments && (
      <>
        <Box display={'flex'} flexDirection={'column'} alignItems="center">
          {/* Title: Project Details */}
          <Typography {...StylesForm.text('h6')}>
            {status} Assignments
          </Typography>

          <Divider />

          {/* Start of Info: Title, Course, Department, Year, Clients, Instructor */}
          <>
            <Typography {...StylesForm.text('p')}>
              Total Assignments: <strong>{`${assignments?.length}`}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Best in Department Assignments:{' '}
              <strong>{filter('Department').length}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Best In College Assignments:{' '}
              <strong>{filter('BestInCollege').length}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Innovation Assignments:{' '}
              <strong>{filter('Innovation').length}</strong>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Other Assignments: <strong>{filter('Other').length}</strong>
            </Typography>
          </>
          {/* End of Info */}

          <br />

          <Link to="/judge/dashboard/assignments">
            <Button variant="contained">View All Assignments</Button>
          </Link>
        </Box>
      </>
    )
  );
};

// Widget for Judge User Details
const InformJudgeDetails = () => {
  const { user } = useAuth();
  return (
    <>
      {/* Title: Project Details */}
      <Typography {...StylesForm.text('h6')}>Judge User Details</Typography>

      <Divider />

      {/* Start of Info: Title, Course, Department, Year, Clients, Instructor */}
      <>
        <Typography {...StylesForm.text('p')}>
          Name: <strong>{`${user?.fullName}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Course: <strong>{`${user?.course}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Department: <strong>{`${user?.department}`}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Competition Participant Year: <strong>{`${user?.year}`}</strong>
        </Typography>
      </>
      {/* End of Info */}

      <Divider />
    </>
  );
};

// Inform Project Bids
const InformProjectBids = () => {
  return (
    <>
      {/* Title: Project Bid */}
      <Typography {...StylesForm.text('h6')}>Project Bid</Typography>

      <Divider />

      {/* Bid On Project */}

      <Typography {...StylesForm.text('p')}>
        You can browse projects based on your course and department. You can bid
        on them to notify the administrators that you are interested in judging
        the project.
      </Typography>

      <Box
        display="flex"
        justifyContent={'center'}
        alignContent={'center'}
        marginY={2}
      >
        <Link to="/judge/dashboard/projects">
          <Button variant="contained">Browse Projects</Button>
        </Link>
      </Box>
    </>
  );
};

// Page that shows when a project is already created
const JudgeDash = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user?.role !== 'JUDGE') {
          navigate('/');
          return;
        }
        const response = await getMyAssignments();
        setAssignments(response?.data?.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}

        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Judge Dashboard</Paper>
            </Grid>

            {/* Inner Grid Item 2: User Detials */}
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Paper
                {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
              >
                <InformJudgeDetails />
              </Paper>
            </Grid>

            {/* Inner Grid Item 2: Pending Assignments */}
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Paper
                {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
              >
                <InformAssignments
                  assignments={assignments.filter(
                    (a) => a.status === 'PENDING'
                  )}
                  status={'Pending'}
                />
              </Paper>
            </Grid>

            {/* Inner Grid Item 3: completed Assignments */}
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Paper
                {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
              >
                <InformAssignments
                  assignments={assignments.filter(
                    (a) => a.status === 'COMPLETED'
                  )}
                  status={'Completed'}
                />
              </Paper>
            </Grid>

            {/* Inner Grid Item 4: */}
            {user?.course === 'SD2' && (
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 330, md: 'auto' })}
                >
                  <InformProjectBids />
                </Paper>
              </Grid>
            )}

            {/* Inner Grid Item 5: */}

            <Grid item lg={4} md={6} sm={12} xs={12}>
              <Paper
                {...Styles.dashboardContentWidget({ lg: 330, md: 'auto' })}
              >
                <CheckIn />
              </Paper>
            </Grid>

            {/*End of Inner Grid */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function JudgeDashboard() {
  return <Dashboard Page={JudgeDash} />;
}
