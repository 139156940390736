import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Admin/Dashboard.js';
import { useState, useEffect } from 'react';
import { Styles } from '../../../styles/global.js';
import { getAssignemnts } from '../../../utils/API.js';
import 'chart.js/auto';

import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import { ModalProvider } from '../../../components/Assignments/ModalContext.js';

function determineCompettion(assignment) {
  const competition = assignment.rubricCopy.type;
  if (competition === 'BestInCollege') {
    return 'Best In College';
  }
  if (competition === 'Department') {
    return 'Best In Department';
  }
  if (competition === 'Innovation') {
    return 'Innovation';
  }
  if (competition === 'Other') {
    return 'Other';
  }
  return 'Unknown';
}

function getAssignmentTable(assignments, filters) {
  if (!assignments || !assignments?.length) {
    return undefined;
  }
  return assignments?.map((assignment) => {
    return {
      id: assignment.projectDetails._id,
      competiton: determineCompettion(assignment),
      project: assignment.projectDetails.title,
      course: assignment.projectDetails.course,
      department: assignment.projectDetails.department,
      status: assignment.status,
      scores: assignment.scores?.join(', ') || '',
      final: assignment.aggregateScore || '',
      owners: assignment.projectDetails.ownersDescription,
    };
  });
}

function aggregateAssignments(assignments) {
  if (!assignments || !assignments?.length) {
    return undefined;
  }
  const grouped = assignments.reduce((acc, assignment) => {
    const projectId = assignment.id;

    if (!acc[projectId]) {
      acc[projectId] = {
        id: projectId,
        competition: assignment.competiton,
        project: assignment.project,
        course: assignment.course,
        department: assignment.department,
        totalScore: 0,
        count: 0,
        completedAssignments: 0,
        totalAssignments: 0,
      };
    }

    acc[projectId].totalScore += assignment.final || 0;
    acc[projectId].count++;
    acc[projectId].totalAssignments++;

    if (assignment.status === 'COMPLETED') {
      acc[projectId].scores = acc[projectId].scores
        ? `${acc[projectId].scores}, ${assignment.final}`
        : `${assignment.final}`;
    }

    if (assignment.status === 'COMPLETED') {
      acc[projectId].completedAssignments++;
    }

    if (assignment?.owners) {
      acc[projectId].members = assignment.owners
        .map((member) => member.fullName)
        .join(', ');
    }

    return acc;
  }, {});

  return Object.values(grouped)
    .map((project) => ({
      id: project.id,
      project: project.project,
      course: project.course,
      department: project.department,
      averageScore:
        project.completedAssignments > 0
          ? (project.totalScore / project.completedAssignments).toFixed(2)
          : (0).toFixed(2),

      status: `${project.completedAssignments}/${project.totalAssignments}`,
      scores: project.scores,
      members: project.members || '',
      isCompleted: project.completedAssignments === project.totalAssignments,
    }))
    ?.sort((a, b) => b.averageScore - a.averageScore);
}

function ViewScores({ assignments }) {
  const paginationModel = { page: 0, pageSize: 10 };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      sortable: true,
      filterable: true,
    },
    {
      field: 'project',
      headerName: 'Project',
      width: 130,
    },
    {
      field: 'course',
      headerName: 'Course',
      width: 100,
      type: 'singleSelect',
      valueOptions: ['SD1', 'SD2'],
    },
    {
      field: 'department',
      headerName: 'Dept',
      width: 100,
      type: 'singleSelect',
      valueOptions: ['CIS', 'ECE', 'ME', 'IMSE'],
    },
    {
      field: 'members',
      headerName: 'Members',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Graded',
      width: 100,
      renderCell: (params) => {
        const status = params?.row?.status;
        return (
          <Chip
            label={status}
            color={params?.row?.isCompleted ? 'success' : 'warning'}
          />
        );
      },
    },
    {
      field: 'scores',
      headerName: 'Scores',
    },
    {
      field: 'averageScore',
      headerName: 'Average Score',
      width: 150,
      flex: 1,
    },
  ];

  const transformedAssignments = getAssignmentTable(assignments);

  const aggregatedAssignments = aggregateAssignments(transformedAssignments);

  return !!aggregatedAssignments ? (
    <>
      <DataGrid
        rows={aggregatedAssignments}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[10, 25, 50, 100]}
        components={{
          Toolbar: () => (
            <GridToolbar>
              <GridToolbarExport />
            </GridToolbar>
          ),
        }}
        sx={{
          height: 500, // Ensure it has a height
          border: '1px solid #ddd',
          borderRadius: 2,
          overflow: 'hidden',
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-root': {
            backgroundColor: 'white',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '0.9rem',
            borderBottom: '1px solid #eee',
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#f4f4f4',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e3f2fd',
          },
        }}
        checkboxSelection={false}
        isRowSelectable={false}
        disableRowSelectionOnClick
      />
    </>
  ) : (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <p>No data available</p>
    </Box>
  );
}

function ParametersForm({ form, setForm, onSubmit }) {
  const disabledButton = React.useCallback(() => {
    if (!form.rubricType) {
      return true;
    }
    if (
      (form?.rubricType === 'BestInCollege' ||
        form?.rubricType === 'Innovation') &&
      !form.year
    ) {
      return true;
    }
    if (form?.rubricType === 'Department' || form?.rubricType === 'Other') {
      if (!form.course || !form.department) {
        return true;
      }
    }
    if (!form.year) {
      return true;
    }
    return false;
  }, [form]);
  return (
    <Box flexWrap={'wrap'} display={'flex'}>
      <FormControl width={'100%'} sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-label">Competition</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={form.rubricType}
          label="Competition"
          onChange={(e) => {
            let additionalParams = { course: '', department: '' };
            if (
              e.target.value === 'BestInCollege' ||
              e.target.value === 'Innovation'
            ) {
              additionalParams = {
                course: 'SD2',
                department: '',
              };
            }
            setForm({
              ...form,
              rubricType: e.target.value,
              ...additionalParams,
            });
          }}
        >
          <MenuItem value={'BestInCollege'}>Best In College</MenuItem>
          <MenuItem value={'Department'}>Best In Department</MenuItem>
          <MenuItem value={'Innovation'}>Innovation</MenuItem>
          <MenuItem value={'Other'}>Other</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        width={'100%'}
        sx={{ m: 1, minWidth: 200 }}
        disabled={
          form.rubricType === 'BestInCollege' ||
          form.rubricType === 'Innovation'
        }
      >
        <InputLabel id="demo-simple-select-label">Course</InputLabel>
        <Select
          labelId="demo-simple-select-label2"
          id="demo-simple-select2"
          value={form.course}
          label="Course"
          onChange={(e) => {
            setForm({
              ...form,
              course: e.target.value,
            });
          }}
        >
          <MenuItem value={'SD1'}>Senior Design 1</MenuItem>
          <MenuItem value={'SD2'}>Senior Design 2</MenuItem>
          <MenuItem value={''}>All</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        width={'100%'}
        sx={{ m: 1, minWidth: 200 }}
        disabled={
          form.rubricType === 'BestInCollege' ||
          form.rubricType === 'Innovation'
        }
      >
        <InputLabel id="demo-simple-select-label">Department</InputLabel>
        <Select
          labelId="demo-simple-select-label2"
          id="demo-simple-select2"
          value={form.department}
          label="Department"
          onChange={(e) => {
            setForm({
              ...form,
              department: e.target.value,
            });
          }}
        >
          <MenuItem value={'CIS'}>CIS</MenuItem>
          <MenuItem value={'ECE'}>ECE</MenuItem>
          <MenuItem value={'ME'}>ME</MenuItem>
          <MenuItem value={'IMSE'}>IMSE</MenuItem>
          <MenuItem value={''}>All</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Year"
        value={form.year}
        onChange={(e) => {
          setForm({
            ...form,
            year: e.target.value,
          });
        }}
        defaultValue={new Date().getFullYear()}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ m: 1, minWidth: 200 }}
      />
      <Button
        variant="outlined"
        onClick={() => onSubmit()}
        sx={{ m: 1 }}
        disabled={disabledButton()}
      >
        Generate Ranking
      </Button>
    </Box>
  );
}

function Scores() {
  let [assignments, setAssignments] = useState({});
  const [filteredAssignments, setFilteredAssignments] = useState({});
  const [form, setForms] = useState({
    rubricType: '',
    course: '',
    department: '',
    year: new Date().getFullYear(),
  });
  const [showTable, setShowTable] = useState(false);

  const generateRanking = () => {
    if (!form) {
      return;
    }

    form.year = Number(form.year);

    let filters = { ...form };
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) {
        delete filters[key];
      }
    });

    const updatedAssignments = assignments?.filter((assignment) => {
      let match = true;
      Object.keys(filters).forEach((key) => {
        if (key === 'rubricType') {
          if (assignment.rubricCopy.type !== filters[key]) {
            match = false;
          }
        } else {
          if (filters[key] !== assignment?.projectDetails[key]) {
            match = false;
          }
        }
      });
      return match;
    });
    setFilteredAssignments(updatedAssignments);

    setShowTable(true);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const assignments = await getAssignemnts();
        setAssignments(assignments?.data?.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Aggregate Scoreboard
              </Paper>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
              <ParametersForm
                form={form}
                setForm={setForms}
                onSubmit={generateRanking}
              />
            </Grid>

            {/*Inner Grid Item 3:  View Deadline Config */}
            {showTable && (
              <Grid item lg={12} sm={12} xs={12}>
                <Paper {...Styles.dashboardContentWidget('auto')}>
                  <ModalProvider>
                    <ViewScores
                      assignments={filteredAssignments}
                      setAssignments={setAssignments}
                    />
                  </ModalProvider>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardScores() {
  return (
    <Dashboard Page={Scores} PageName="(A) Dashboard - Aggregate Scores" />
  );
}
