import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Faculty/Dashboard.js';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';
import {
  getMainConfig,
  getProjects,
  getStats,
  getUsers,
  objectToQueryString,
} from '../../../utils/API.js';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import Modal from '../../../components/Modal.js';
import Accordion from '../../../components/CompletionAccordians.js';

import { Typography, Divider, Button } from '@mui/material';
import { formatDate } from '../../../utils/dates.js';
import { useAuth } from '../../../utils/AuthContext.js';
import PosterTempaltes from '../../../components/Faculty/PosterTemplates.js';

const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        // Customize the tooltip to show percentage
        label: (tooltipItem) => {
          const total = tooltipItem.dataset.data.reduce(
            (sum, val) => sum + val,
            0
          );
          const percentage = ((tooltipItem.raw / total) * 100).toFixed(1);
          return `${tooltipItem.label}: ${percentage}% | ${tooltipItem.raw}`; // Add percentage to the tooltip label
        },
      },
    },
    legend: {
      position: 'top',
    },
  },
  animation: {
    animateScale: true,
  },
};

// Displays Due Dates
const DatesWidget = ({ dates }) => {
  return (
    <>
      {/* Title: Due Dates */}
      <Typography {...StylesForm.text('h6')}>Due Dates</Typography>
      <Divider />
      {/* Start of Info: */}
      {dates && (
        <>
          <Typography {...StylesForm.text('p')}>
            Abstract:
            <strong> {formatDate(dates?.abstract)}</strong>
          </Typography>

          <Typography {...StylesForm.text('p')}>
            Poster Draft:
            <strong> {formatDate(dates?.posterImage)}</strong>
          </Typography>

          <Typography {...StylesForm.text('p')}>
            Poster Final:
            <strong> {formatDate(dates?.posterImageFinal)}</strong>
          </Typography>

          <Divider />
        </>
      )}
    </>
  );
};

// Displays Project Link Completion Distribution
const PosterImageWidget = ({ stats }) => {
  const [open, setOpen] = useState(false);
  const [complete, setComplete] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getProjects(objectToQueryString(params));
      setIncomplete(
        response?.data?.data?.filter((project) =>
          [
            'CIS_Default.png',
            'IMSE_Default.png',
            'ME_Default.png',
            'ECE_Default.png',
          ].includes(project?.posterImage)
        ) || []
      );
      setComplete(
        response?.data?.data?.filter(
          (project) =>
            ![
              'CIS_Default.png',
              'IMSE_Default.png',
              'ME_Default.png',
              'ECE_Default.png',
            ].includes(project?.posterImage)
        ) || []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const data = {
    labels: ['Completed', 'Pending'],
    datasets: [
      {
        label: '# of Poster Image Submissions',
        data: [
          stats?.data?.totalProjects - stats?.data?.defaultPosterImage,
          stats?.data?.defaultPosterImage,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Poster Image Widget */}
      <Typography {...StylesForm.text('h6')}>
        Poster Image Submissions
      </Typography>
      <Divider />

      {/* Start of Pie Chart: Poster Image Completions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} options={options} />
        </Box>
      </Box>

      {/* Detailed Completions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed completions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Accordion
          title={'Poster Image Submissions'}
          completeList={complete}
          incompleteList={incomplete}
        />
      </Modal>
    </>
  );
};

// Displays Project Link Completion Distribution
const PosterLinkWidget = ({ stats }) => {
  const [open, setOpen] = useState(false);
  const [complete, setComplete] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getProjects(objectToQueryString(params));
      setIncomplete(
        response?.data?.data?.filter((project) => project?.posterLink === '') ||
          []
      );
      setComplete(
        response?.data?.data?.filter((project) => project?.posterLink !== '') ||
          []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const data = {
    labels: ['Completed', 'Pending'],
    datasets: [
      {
        label: '# of Poster Links Submissions',
        data: [
          stats?.data?.totalProjects - stats?.data?.noPosterLink,
          stats?.data?.noPosterLink,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Poster Link Widget */}
      <Typography {...StylesForm.text('h6')}>
        Poster File (.pdf) Submissions
      </Typography>
      <Divider />

      {/* Start of Pie Chart: Abstract Completions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} options={options} />
        </Box>
      </Box>

      {/* Detailed Completions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed completions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Accordion
          title={'Poster File (.pdf) Submissions'}
          completeList={complete}
          incompleteList={incomplete}
        />
      </Modal>
    </>
  );
};

// Displays Abstract Completion Distribution
const AbstractWidget = ({ stats }) => {
  const [open, setOpen] = useState(false);
  const [complete, setComplete] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();

  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getProjects(objectToQueryString(params));
      setIncomplete(
        response?.data?.data?.filter((project) => project?.abstract === '') ||
          []
      );
      setComplete(
        response?.data?.data?.filter((project) => project?.abstract !== '') ||
          []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const data = {
    labels: ['Completed', 'Pending'],
    datasets: [
      {
        label: '# of Abstract Submissions',
        data: [
          stats?.data?.totalProjects - stats?.data?.noAbstract,
          stats?.data?.noAbstract,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Abstract Widget */}
      <Typography {...StylesForm.text('h6')}>Abstract Submissions</Typography>
      <Divider />
      {/* Start of Pie Chart: Abstract Completions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} options={options} />
        </Box>
      </Box>

      {/* Detailed Completions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed completions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Accordion
          title={'Abstract Completions'}
          completeList={complete}
          incompleteList={incomplete}
        />
      </Modal>
    </>
  );
};

// Displays Students Details
const StudentsWidget = () => {
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getUsers(objectToQueryString(params));
      setUsers(response?.data?.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  return (
    <>
      {/* Title: Student Details */}
      <Typography {...StylesForm.text('h6')}>
        Student Users (w/Filters)
      </Typography>
      <Divider />
      {/* Start of Info: Students*/}
      <>
        <Typography {...StylesForm.text('p')}>
          Students:
          <strong>
            {' ' + users?.filter((user) => user?.role === 'STUDENT')?.length}
            {users?.filter((user) => user?.role === 'STUDENT')?.length > 1
              ? ' Users'
              : ' User'}
          </strong>
        </Typography>

        <Divider />
      </>
      {/* Link to users link */}
      <Box display={'flex'} alignItems={'end'} height={'100%'}>
        <Link
          to={`/faculty/dashboard/users?${searchParams.toString()}`}
          style={{ color: '#1976D2', margin: 3 }}
        >
          To view the users, click here
        </Link>
      </Box>
    </>
  );
};

const ClassWidget = ({ stats }) => {
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  return (
    <>
      {/* Title: Project Details */}
      <Typography {...StylesForm.text('h6')}>Dashboard Filters</Typography>
      <Divider />

      {/* Start of Info: Course, Department, Year, Project Count*/}
      <>
        <Typography {...StylesForm.text('p')}>
          Department: <strong> {user?.department || 'ALL'}</strong>
        </Typography>

        {user?.department !== 'ME' && (
          <Typography {...StylesForm.text('p')}>
            Course: <strong> {searchParams?.get('course') || 'ALL'}</strong>
          </Typography>
        )}

        <Typography {...StylesForm.text('p')}>
          Year: <strong> {searchParams?.get('year') || 'ALL'}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Count:
          <strong>
            {' ' + stats?.data?.totalProjects}
            {stats?.data?.totalProjects > 1 ? ' Projects' : ' Project'}
          </strong>
        </Typography>
        <Divider />
      </>

      {/* Link to add poster link */}
      <Box display={'flex'} alignItems={'end'} height={'100%'}>
        <Link
          to={`/faculty/dashboard/projects`}
          style={{ color: '#1976D2', margin: 3 }}
        >
          To view the student projects, click here
        </Link>
      </Box>
    </>
  );
};

// Page that shows when a project is already created
const FacultyDash = () => {
  const [stats, setStats] = useState(null);
  const [searchParams] = useSearchParams();
  const [config, setConfig] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {};
        if (searchParams?.get('department'))
          params.department = searchParams?.get('department');
        if (searchParams?.get('year')) params.year = searchParams?.get('year');
        if (searchParams?.get('course'))
          params.course = searchParams?.get('course');
        const response = await getStats(objectToQueryString(params));
        setStats(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('#');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [searchParams]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        {stats && (
          <Container {...Styles.customContainer()}>
            {/*Outer Grid Container */}
            <Grid container spacing={3}>
              {/*Inner Grid Begins*/}

              {/*Inner Grid Item 1: Page Title */}
              <Grid item lg={12} sm={12} xs={12}>
                <Paper {...Styles.dashboardPageWidget()}>
                  Faculty Dashboard
                </Paper>
              </Grid>

              {/*Inner Grid Item 2: Dashboard Filters */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <ClassWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 2b: Student Users*/}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <StudentsWidget />
                </Paper>
              </Grid>

              {/*Inner Grid Item 2b: Student Users*/}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <PosterTempaltes />
                </Paper>
              </Grid>

              {/*Inner Grid Item 3: Abstract Widget */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <AbstractWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 4: Project Abstract */}
              {
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <Paper
                    {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                  >
                    <PosterLinkWidget stats={stats} />
                  </Paper>
                </Grid>
              }

              {/*Inner Grid Item 5: Poster Image Widget */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <PosterImageWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 6: Due Dates */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <DatesWidget dates={config?.dueDates} />
                </Paper>
              </Grid>

              {/*End of Inner Grid*/}
            </Grid>
          </Container>
        )}
      </Box>
    </>
  );
};

export default function FacultyDashboard() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const params = {};
    if (searchParams.get('department'))
      params.department = searchParams.get('department');
    if (searchParams.get('year')) params.year = searchParams.get('year');
    else params.year = new Date().getFullYear();
    if (searchParams.get('course')) params.course = searchParams.get('course');
    navigate(`/faculty/dashboard?${objectToQueryString(params)}`);
  }, [navigate, searchParams]);
  return <Dashboard Page={FacultyDash} PageName="Faculty Dashboard" />;
}
