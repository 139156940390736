import {
  PROJECT_ADMIN_SECTIONS as sections,
  PROJECT_JUDGE_FIELDS as fields,
} from './constants';

const JudgeProjectsSchema = ({ avaliableYears }) => {
  return {
    [sections.department]: {
      title: {
        value: sections.department,
      },
      fields: {
        name: fields.department.name,
        type: 'select',
        label: 'Department',
        placeholder: 'Select department',
        required: false,
        getOptions: () => fields.department.options,
      },
    },
    [sections.year]: {
      title: {
        value: sections.year,
      },
      fields: {
        name: fields.year.name,
        type: 'select',
        label: 'Year',
        placeholder: 'Select year',
        required: false,
        getOptions: () => fields.year.options(avaliableYears),
      },
    },
    [sections.limit]: {
      title: {
        value: sections.limit,
      },
      fields: {
        name: fields.limit.name,
        type: 'select',
        label: 'Year',
        placeholder: 'Select limit',
        required: false,
        getOptions: () => fields.limit.options,
      },
    },
  };
};

export default JudgeProjectsSchema;
