import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Chip, Divider, Paper } from '@mui/material';

export const JudgeCard = ({ judge }) => {
  return (
    <Paper elevation={1} sx={{ margin: 2 }}>
      <Box padding={2} margin={1}>
        <h4>{judge.fullName}</h4>
        <Divider />
        <Box padding={1}>
          <p>Judge ID: {judge._id}</p>
          <p>Email: {judge.email}</p>
          <Box display="flex" justifyContent="start">
            <Chip label={judge.course} />
            <Chip label={judge.department} />
            <Chip label={judge.year} />
            <Chip label={judge.interest} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default function AccordionUsage({
  title,
  completeList,
  incompleteList,
  completeLabel = 'Completed',
  incompleteLabel = 'Pending',
}) {
  return (
    <Box padding={2} margin={3}>
      <h3>{title}</h3>
      <Divider />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {completeLabel}: {`(Results: ${completeList?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {completeList && (
            <>
              {completeList.map((judge) => (
                <JudgeCard judge={judge} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {incompleteLabel}: {`(Results: ${incompleteList?.length || 0})`}
        </AccordionSummary>
        <AccordionDetails>
          {incompleteList && (
            <>
              {incompleteList.map((judge) => (
                <JudgeCard judge={judge} />
              ))}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
