import React, { useEffect } from 'react';
import './index.css';
import Home from './pages/Home';
import ParticipantInfo from './pages/Participant-Info';
import ParticipantInfoSeniorDesign1 from './pages/ParticipantInfo/SeniorDesign1.js';
import ParticipantInfoSeniorDesign2 from './pages/ParticipantInfo/SeniorDesign2.js';
import EventAtendeeInfo from './pages/Event-Atendee-Info';
import Contact from './pages/Contact';
import NotFound from './pages/Not-Found';
import Student from './pages/Portal/Student.js';
import Faculty from './pages/Portal/Faculty.js';
import StudentLogin from './pages/Student/Login.js';
import FacultyLogin from './pages/Faculty/Login.js';
import ProjectDashboard from './pages/Student/Dashboard/Dashboard.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import StudentSignUp from './pages/Student/Signup.js';
import ProjectAbstract from './pages/Student/Dashboard/Abstract.js';
import ProjectPoster from './pages/Student/Dashboard/Poster.js';
import ProjectInvites from './pages/Student/Dashboard/Invites.js';
import JudgeSignUp from './pages/Judge/Signup/[token].js';
import FacultyDashboard from './pages/Faculty/Dashboard/index.js';
import AdminDashboard from './pages/Admin/Dashboard/index.js';
import FacultyStudents from './pages/Faculty/Dashboard/users.js';
import AdminDashboardUsers from './pages/Admin/Dashboard/users.js';
import AdminDashboardProjects from './pages/Admin/Dashboard/projects/index.js';
import AdminDashboardProject from './pages/Admin/Dashboard/projects/[pid].js';
import FacultyDashboardProject from './pages/Faculty/Dashboard/projects/[pid].js';
import FacultyDashboardProjects from './pages/Faculty/Dashboard/projects/index.js';
import AdminDashboardConfig from './pages/Admin/Dashboard/config.js';
import AdminDashboardTutorials from './pages/Admin/Dashboard/tutorials/index.js';
import AdminDashboardTutorial from './pages/Admin/Dashboard/tutorials/[tid].js';
import FacultyDashboardTutorials from './pages/Faculty/Dashboard/tutorials/index.js';
import FacultyDashboardTutorial from './pages/Faculty/Dashboard/tutorials/[tid].js';
import StudentDashboardTutorials from './pages/Student/Dashboard/tutorials/index.js';
import StudentDashboardTutorial from './pages/Student/Dashboard/tutorials/[tid].js';
import AdminDashboardRubrics from './pages/Admin/Dashboard/rubrics/index.js';
import AdminDashboardRubric from './pages/Admin/Dashboard/rubrics/[rid].js';
import Judge from './pages/Portal/Judge.js';
import JudgeLogin from './pages/Judge/Login.js';
import JudgeDashboard from './pages/Judge/Dashboard/index.js';
import JudgeDashboardAssignments from './pages/Judge/Dashboard/Assignments/index.js';
import JudgeDashboardAssignment from './pages/Judge/Dashboard/Assignments/[aid].js';
import JudgeDashboardProjects from './pages/Judge/Dashboard/Projects/index.js';
import JudgeDashboardProject from './pages/Judge/Dashboard/Projects/[pid].js';
import AdminDashboardReports from './pages/Admin/Dashboard/reports.js';
import PreviousYears from './pages/PreviousYears.js';
import StudentVerify from './pages/Student/Verify.js';
import Forgot from './pages/Password/forgot.js';
import Reset from './pages/Password/reset/token.js';
import LoadingPage from './pages/Loading.js';
import AdminDashboardAssignments from './pages/Admin/Dashboard/assignments.js';
import AdminDashboardScores from './pages/Admin/Dashboard/scoreboard.js';
import ProjectDisplayRequest from './pages/Student/Dashboard/DisplayRequest.js';
import AdminDashboardBids from './pages/Admin/Dashboard/bids.js';
const App = () => {
  const isCMSActive = !!process.env.REACT_APP_CMS;
  const CMS = process.env.REACT_APP_CMS;

  const ExternalRedirect = ({ url }) => {
    useEffect(() => {
      window.location.href = url;
    }, []);
    return <LoadingPage />;
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          {isCMSActive ? (
            <>
              <Route path="/" element={<ExternalRedirect url={CMS} />} />
              <Route
                path="/participant-info"
                element={<ExternalRedirect url={CMS + '/event-info'} />}
              />
              <Route
                path="/contact"
                element={<ExternalRedirect url={CMS + '/contact'} />}
              />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/participant-info" element={<ParticipantInfo />} />
              <Route
                path="/participant-info/senior-design-1"
                element={<ParticipantInfoSeniorDesign1 />}
              />
              <Route
                path="/participant-info/senior-design-2"
                element={<ParticipantInfoSeniorDesign2 />}
              />
              <Route
                path="/event-attendee-info"
                element={<EventAtendeeInfo />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/previous-years" element={<PreviousYears />} />
              <Route path="/portal/student" element={<Student />} />
              <Route path="/portal/faculty" element={<Faculty />} />
              <Route path="/portal/judge" element={<Judge />} />
            </>
          )}
          <Route path="/student/login" element={<StudentLogin />} />
          <Route path="/student/verify" element={<StudentVerify />} />
          <Route path="/password/forgot" element={<Forgot />} />
          <Route path="/password/reset/:token" element={<Reset />} />
          <Route
            path="/student/signup/VERIFIED/UMICH/STUDENT/A7k3B2qL9f"
            element={<StudentSignUp />}
          />
          <Route path="/faculty/login" element={<FacultyLogin />} />
          <Route path="/judge/login" element={<JudgeLogin />} />
          <Route
            path="/student/dashboard/project/:pid"
            element={<ProjectDashboard />}
          />
          <Route
            path="/student/dashboard/project/:pid/invites"
            element={<ProjectInvites />}
          />
          <Route
            path="/student/dashboard/project/:pid/abstract"
            element={<ProjectAbstract />}
          />
          <Route
            path="/student/dashboard/project/:pid/display-request"
            element={<ProjectDisplayRequest />}
          />
          <Route
            path="/student/dashboard/project/:pid/poster"
            element={<ProjectPoster />}
          />
          <Route
            path="/student/dashboard/project/:pid/tutorials"
            element={<StudentDashboardTutorials />}
          />
          <Route
            path="/student/dashboard/project/:pid/tutorials/:tid"
            element={<StudentDashboardTutorial />}
          />
          <Route
            path="/student/dashboard/project/:pid/rubrics/:rid"
            element={<AdminDashboardRubric key="student" />}
          />
          <Route
            path="/student/dashboard/project/:pid/rubrics"
            element={<AdminDashboardRubrics key="student" />}
          />

          <Route
            path="/judge/signup/:token"
            element={<JudgeSignUp title="Judge" />}
          />
          <Route
            path="/faculty/signup/:token"
            element={<JudgeSignUp title="Faculty" />}
          />
          <Route
            path="/admin/signup/:token"
            element={<JudgeSignUp title="Admin" />}
          />
          <Route path="/faculty/dashboard" element={<FacultyDashboard />} />
          <Route
            path="/faculty/dashboard/users"
            element={<FacultyStudents />}
          />
          <Route
            path="/faculty/dashboard/projects"
            element={<FacultyDashboardProjects />}
          />
          <Route
            path="/faculty/dashboard/projects/:pid"
            element={<FacultyDashboardProject />}
          />

          <Route
            path="/faculty/dashboard/tutorials"
            element={<FacultyDashboardTutorials />}
          />
          <Route
            path="/faculty/dashboard/tutorials/:tid"
            element={<FacultyDashboardTutorial />}
          />
          <Route
            path="/faculty/dashboard/rubrics"
            element={<AdminDashboardRubrics key="faculty" />}
          />
          <Route
            path="/faculty/dashboard/rubrics/:rid"
            element={<AdminDashboardRubric key="faculty" />}
          />
          <Route
            path="/faculty/dashboard/reports"
            element={<AdminDashboardReports key="faculty" />}
          />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route
            path="/admin/dashboard/users"
            element={<AdminDashboardUsers />}
          />
          <Route
            path="/admin/dashboard/projects"
            element={<AdminDashboardProjects />}
          />
          <Route
            path="/admin/dashboard/projects/:pid"
            element={<AdminDashboardProject />}
          />
          <Route
            path="/admin/dashboard/tutorials"
            element={<AdminDashboardTutorials />}
          />
          <Route
            path="/admin/dashboard/tutorials/:tid"
            element={<AdminDashboardTutorial />}
          />
          <Route
            path="/admin/dashboard/rubrics"
            element={<AdminDashboardRubrics key="admin" />}
          />
          <Route
            path="/admin/dashboard/rubrics/:rid"
            element={<AdminDashboardRubric key="admin" />}
          />
          <Route
            path="/admin/dashboard/config"
            element={<AdminDashboardConfig />}
          />
          <Route
            path="/admin/dashboard/reports"
            element={<AdminDashboardReports key="admin" />}
          />
          <Route
            path="/admin/dashboard/assignments"
            element={<AdminDashboardAssignments />}
          />
          <Route
            path="/admin/dashboard/bids"
            element={<AdminDashboardBids />}
          />
          <Route
            path="/admin/dashboard/scoreboard"
            element={<AdminDashboardScores />}
          />
          <Route path="/judge/dashboard" element={<JudgeDashboard />} />
          <Route
            path="/judge/dashboard/assignments"
            element={<JudgeDashboardAssignments />}
          />

          <Route
            path="/judge/dashboard/assignments/:aid"
            element={<JudgeDashboardAssignment />}
          />
          <Route
            path="/judge/dashboard/projects"
            element={<JudgeDashboardProjects />}
          />
          <Route
            path="/judge/dashboard/projects/:pid"
            element={<JudgeDashboardProject />}
          />

          {isCMSActive ? (
            <Route path="*" element={<ExternalRedirect url={CMS} />} />
          ) : (
            <Route path="*" element={<NotFound />} />
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
