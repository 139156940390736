import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { useModal } from './ModalContext';
import {
  Close,
  Delete,
  Person,
  Phone,
  Scoreboard,
  InfoOutlined,
} from '@mui/icons-material';
import DeleteableRubric from '../Rubrics/DeletableRubric';
import ScorableRubric from '../Rubrics/ScorableRubric';
import { useEffect, useState } from 'react';
import { getUsers, reassignJudgeAssignment } from '../../utils/API';
import { useFeedback } from '../../utils/FeedbackContext';
// create me a context and a provider for the modal with useReducer to manage the state

const formatRubricType = (type) => {
  switch (type) {
    case 'BestInCollege':
      return 'Best In College';
    case 'Department':
      return 'Best In Department';
    case 'Innovation':
      return 'Innovation';
    case 'Other':
      return 'Other';
    default:
      return 'Unknown';
  }
};

const GroupedRows = ({ title, children }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      border={1}
      borderColor={'#e0e0e0'}
      padding={2}
      borderRadius={2}
      boxShadow={'0px 2px 4px rgba(0, 0, 0, 0.05)'}
      marginBottom={2}
    >
      <Typography variant="h6" fontWeight={600} marginBottom={2}>
        {title}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {children}
      </Box>
    </Box>
  );
};

const DetailRow = ({ label, value }) => {
  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant="body1" fontWeight={600}>
          {label}
        </Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );
};

const EditModal = () => {
  const { state, closeModal, setTab, setWidth } = useModal();
  const style = {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%)',
    bgcolor: 'background.paper',
    boxShadow: 5,
    borderRadius: 2,
    p: 4,
    maxHeight: '85vh',
    overflowY: 'auto',
  };

  const Hero = () => {
    return (
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
      >
        <Typography
          variant="h4"
          component="h1"
          fontFamily={'serif'}
          noWrap
          textOverflow={'ellipsis'}
          fontWeight={600}
        >
          {state.assignment?.projectDetails?.title}
        </Typography>
        <Close
          onClick={closeModal}
          sx={{ cursor: 'pointer' }}
          fontSize="medium"
        />
      </Box>
    );
  };

  const TabMenu = () => {
    const handleChange = (event, newValue) => {
      if (newValue === 1 || newValue === 3) {
        setWidth({
          xs: '90vw',
          sm: '90vw',
          md: '90vw',
          lg: '90vw',
          xl: '90vw',
        });
        setTab(newValue);
        return;
      }
      setWidth({ xs: '90vw', sm: '80vw', md: '70vw', lg: '60vw', xl: '50vw' });
      setTab(newValue);
    };

    return (
      <Box width={'100%'} marginBottom={2}>
        <Tabs
          value={state.tab}
          onChange={handleChange}
          aria-label="icon label tabs example"
          centered
        >
          <Tab icon={<InfoOutlined />} label="info" />
          <Tab icon={<Scoreboard />} label="rubric" />
          <Tab icon={<Person />} label="judge" />
          <Tab icon={<Delete />} label="Delete" />
        </Tabs>
      </Box>
    );
  };

  const Info = () => {
    return (
      <Box>
        <GroupedRows title="Project Info">
          <DetailRow
            label="Project Title"
            value={state.assignment?.projectDetails?.title}
          />
          <DetailRow
            label="Course"
            value={state.assignment?.projectDetails?.course}
          />
          <DetailRow
            label="Department"
            value={state.assignment?.projectDetails?.department}
          />
          <DetailRow
            label="Competition Year"
            value={state.assignment?.projectDetails?.year}
          />
          <DetailRow
            label="Owners"
            value={state.assignment?.projectDetails?.ownersDescription
              ?.map((owner) => owner.fullName)
              .join(', ')}
          />
        </GroupedRows>
        <GroupedRows title="Competition Info">
          <DetailRow
            label="Rubric Title"
            value={state.assignment?.rubricCopy?.title}
          />
          <DetailRow
            label="Competition Type"
            value={formatRubricType(state.assignment?.rubricCopy?.type)}
          />
          {state.assignment?.rubricCopy?.course !== 'N/A' && (
            <DetailRow
              label="Course"
              value={state.assignment?.rubricCopy?.course}
            />
          )}
          {state.assignment?.rubricCopy?.department !== 'N/A' && (
            <DetailRow
              label="Department"
              value={state.assignment?.rubricCopy?.department}
            />
          )}
        </GroupedRows>
        <GroupedRows title="Judge Info">
          <DetailRow
            label="Judge Name"
            value={state.assignment?.judgeDetails?.fullName}
          />
          <DetailRow
            label="Check In Status"
            value={
              state.assignment?.judgeDetails?.checkedIn
                ? 'Checked In'
                : 'Not Checked In'
            }
          />
          {state.assignment?.judgeDetails?.course !== 'N/A' && (
            <DetailRow
              label="Course"
              value={state.assignment?.judgeDetails?.course}
            />
          )}
          {state.assignment?.judgeDetails?.department !== 'N/A' && (
            <DetailRow
              label="Department"
              value={state.assignment?.judgeDetails?.department}
            />
          )}
          {state.assignment?.judgeDetails?.interest && (
            <DetailRow
              label="Interest"
              value={state.assignment?.judgeDetails?.interest}
            />
          )}
          <DetailRow
            label="Latest Participation Year"
            value={state.assignment?.judgeDetails?.year}
          />
        </GroupedRows>
        <GroupedRows title="Assignment Info">
          <DetailRow label="Assignment ID" value={state.assignment?._id} />
          <DetailRow
            label="Judge Completion Status"
            value={state.assignment?.status || 'N/A'}
          />
          <DetailRow
            label="Scores"
            value={
              state.assignment?.scores?.map((score) => score).join(', ') ||
              'N/A'
            }
          />
          <DetailRow
            label="Aggregate Score"
            value={state.assignment?.aggregateScore || 'N/A'}
          />
          <DetailRow
            label="Created At"
            value={state.assignment?.createdAt || 'N/A'}
          />
        </GroupedRows>
      </Box>
    );
  };

  const Judge = () => {
    const [judges, setJudges] = useState([]);
    const [judge, setJudge] = useState(undefined);
    const { notifySuccess, notifyError } = useFeedback();

    const handleButton = async () => {
      try {
        await reassignJudgeAssignment(state.assignment._id, judge);
        notifySuccess('Judge reassigned successfully');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.error('Error reassigning judge:', error);
        notifyError('Error reassigning judge');
      }
    };

    useEffect(() => {
      const fetchJudges = async () => {
        try {
          const response = await getUsers(
            `role=JUDGE&year=${state.assignment?.projectDetails?.year}`
          );
          if (!response?.data?.data) return;
          setJudges(response?.data?.data);
        } catch (error) {
          console.error('Error fetching judges:', error);
        }
      };
      fetchJudges();
    }, []);

    return (
      <Box>
        <GroupedRows title="Judge Info">
          <DetailRow
            label="Judge Name"
            value={state.assignment?.judgeDetails?.fullName}
          />
          <DetailRow
            label="Check In Status"
            value={
              state.assignment?.judgeDetails?.checkedIn
                ? 'Checked In'
                : 'Not Checked In'
            }
          />
          {state.assignment?.judgeDetails?.course !== 'N/A' && (
            <DetailRow
              label="Course"
              value={state.assignment?.judgeDetails?.course}
            />
          )}
          {state.assignment?.judgeDetails?.department !== 'N/A' && (
            <DetailRow
              label="Department"
              value={state.assignment?.judgeDetails?.department}
            />
          )}
          {state.assignment?.judgeDetails?.interest && (
            <DetailRow
              label="Interest"
              value={state.assignment?.judgeDetails?.interest}
            />
          )}
          <DetailRow
            label="Latest Participation Year"
            value={state.assignment?.judgeDetails?.year}
          />
        </GroupedRows>
        <GroupedRows title="Judge Reassignment">
          <Alert severity="info" variant="outlined">
            <Typography variant="body1">
              If you wish to reassign this judge to a different project, please
              select another judge from the dropdown menu below and click the
              "Reassign Judge" button. This will transfer the assignment to the
              selected judge.
            </Typography>
          </Alert>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={judge}
              onChange={(e) => setJudge(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {judges?.map((judge) => (
                <MenuItem key={judge._id} value={judge._id}>
                  <Box display="flex" gap={2} alignItems={'center'}>
                    {judge?.fullName}
                    {judge?.course !== 'N/A' && <Chip label={judge?.course} />}
                    {judge?.department !== 'N/A' && (
                      <Chip label={judge?.department} />
                    )}
                    <Chip label={judge?.year} />
                    {judge?.interest && <Chip label={judge?.interest} />}

                    {!!judge?.assignments?.length && (
                      <>
                        |
                        <Chip
                          label={`${judge?.assignments?.length} Assignments`}
                          icon={<Scoreboard />}
                          color={'success'}
                          variant="outlined"
                        />
                      </>
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              All Available Judges For This Year's Competition
            </FormHelperText>
          </FormControl>
          {judge && (
            <Button variant="contained" color="primary" onClick={handleButton}>
              Reassign Judge
            </Button>
          )}
        </GroupedRows>
      </Box>
    );
  };

  const setFlag = (flag) => {
    // reload after 3 seconds
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  return (
    <>
      {state?.assignment && (
        <Modal
          open={state.open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: { backgroundColor: 'rgba(0, 0, 0, 0.2)' }, // Adjust opacity here
          }}
          closeAfterTransition
        >
          <Fade in={state.open}>
            <Box
              sx={{ ...style }}
              width={state.width}
              border={3}
              borderColor={'#d0d0d0'}
            >
              <Hero />
              <Divider sx={{ marginY: 2 }} />
              <TabMenu />
              <Fade in={state.tab === 0} timeout={600}>
                <div style={{ display: state.tab === 0 ? 'block' : 'none' }}>
                  <Info />
                </div>
              </Fade>
              <Fade in={state.tab === 1} timeout={600}>
                <div style={{ display: state.tab === 1 ? 'block' : 'none' }}>
                  <ScorableRubric
                    assignment={state?.assignment}
                    flag={false}
                    setFlag={setFlag}
                  />
                </div>
              </Fade>
              <Fade in={state.tab === 2} timeout={600}>
                <div style={{ display: state.tab === 2 ? 'block' : 'none' }}>
                  <Judge />
                </div>
              </Fade>
              <Fade in={state.tab === 3} timeout={600}>
                <div style={{ display: state.tab === 3 ? 'block' : 'none' }}>
                  <DeleteableRubric
                    assignment={state?.assignment}
                    flag={false}
                    setFlag={setFlag}
                  />
                </div>
              </Fade>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default EditModal;
