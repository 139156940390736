import { Box, Divider, Typography } from '@mui/material';
import { useAuth } from '../../utils/AuthContext';
import { StylesForm } from '../../styles/global';
import { useEffect, useState } from 'react';
import { getMainConfig } from '../../utils/API';
const PosterTempaltes = () => {
  const { user } = useAuth();
  const [templates, setTemplates] = useState(null);
  let isCS = user?.department === 'CIS';
  let isME = user?.department === 'ME';
  let isECE = user?.department === 'ECE';
  let isIMSE = user?.department === 'IMSE';
  const isADMIN = user?.role === 'ADMIN';

  let departments = [];

  if (isADMIN) {
    departments = ['CIS', 'ME', 'ECE', 'IMSE'];
  } else {
    if (isCS) {
      departments = ['CIS'];
    }
    if (isME) {
      departments = ['ME'];
    }
    if (isECE) {
      departments = ['ECE'];
    }
    if (isIMSE) {
      departments = ['IMSE'];
    }
  }

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await getMainConfig();
        const data = response?.data?.data?.posterTemplatesLinks || [];
        if (!!data[6]) {
          data[6].name = data[6].name.replace(' (SD2)', '');
        }

        setTemplates(response?.data?.data?.posterTemplatesLinks || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchTemplates();
  }, []);

  return (
    <>
      {/* Title: Project Details */}
      <Typography {...StylesForm.text('h6')}>Poster Templates</Typography>
      <Divider />
      {
        <Box display={'flex'} flexDirection={'column'}>
          <ul>
            {templates?.map((template, index) => {
              if (
                departments.some((department) =>
                  template.name.includes(department)
                )
              ) {
                return (
                  <li key={index} style={{ margin: '7px', marginLeft: '0px' }}>
                    <a href={template.link} target="_blank" rel="noreferrer">
                      {template.name}
                    </a>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </Box>
      }
    </>
  );
};

export default PosterTempaltes;
