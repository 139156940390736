import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useAuth } from '../../../utils/AuthContext.js';
import { useNavigate, useParams, Link } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { Alert, TextField, Typography, Divider, Button } from '@mui/material';
import { Styles, StylesForm } from '../../../styles/global.js';
import {
  getMainConfig,
  getProject,
  updatePosterImage,
  updatePosterLink,
} from '../../../utils/API.js';
import { formatDate } from '../../../utils/dates.js';
import {
  InformPosterLink,
  InformPosterImage,
} from '../../../components/Student/Widgets';
import useFeedback from '../../../utils/FeedbackContext/useFeedback.js';
import Task from '../../../components/Task.js';

const determineDate = (draft, final) => {
  if (new Date(draft) > new Date()) {
    return draft;
  } else {
    return final;
  }
};

// Form for Submitting Poster Link
const SubmitPosterLink = ({ setFeedback, setProject }) => {
  const { pid } = useParams();
  const [file, setFile] = useState(null);
  const { notifySuccess, notifyError } = useFeedback();

  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append('pdf', file);
    try {
      const response = await updatePosterLink(pid, formData);

      if (response.data.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        notifySuccess(
          response?.data?.message || 'Poster File (PDF) Submitted Successfully'
        );
        try {
          const response = await getProject(pid);
          setProject(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
      notifyError(
        error.response.data.errMessage || 'Error submitting poster file (pdf)'
      );
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Title: Submit Poster Link */}
          <Typography variant="h6" margin={1}>
            Submit Poster File (.pdf)
          </Typography>

          <Divider />

          {/* Button: Submits Poster Link */}
          <Button
            variant="outlined"
            disabled={!file}
            onClick={handleSubmission}
            sx={{ marginBottom: 1 }}
          >
            Submit
          </Button>
        </Box>

        <Divider />

        {/* Code for Directions + Designated Folder */}
        <>
          {/* Input: Poster Link */}
          {/* Upload Button */}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            sx={{
              height: '100%',
            }}
          >
            <label htmlFor="contained-button-file-2">
              <input
                accept="application/pdf"
                id="contained-button-file-2"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUploadIcon />}
                size="large"
              >
                Upload (.pdf)
              </Button>
            </label>
            <Typography
              variant="p"
              margin={1}
              sx={{
                color: '#1976D2',
              }}
            >
              {file ? file.name : 'File Not Submitted'}
            </Typography>
            <Divider />
          </Box>
        </>
      </>
    </>
  );
};

// Form for Submtting Poster Image
const SubmitPosterImage = ({ setFeedback, setProject }) => {
  const [file, setFile] = useState(null);
  const { pid } = useParams();
  const { notifySuccess, notifyError } = useFeedback();

  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await updatePosterImage(pid, formData);

      if (response.data.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        notifySuccess(
          response?.data?.message || 'Poster Image Submitted Successfully'
        );
        try {
          const response = await getProject(pid);
          setProject(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
      notifyError(
        error.response.data.errMessage || 'Error submitting poster image'
      );
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Title: Submit Poster Image */}
        <Typography variant="h6" margin={1}>
          Submit Poster Image (.jpg or .png)
        </Typography>

        <Divider />

        {/* Button: Submit Image */}
        <Button
          variant="outlined"
          sx={{ marginBottom: 1 }}
          disabled={!file}
          onClick={handleSubmission}
        >
          Submit
        </Button>
      </Box>

      <Divider />

      {/* Upload Button */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
        sx={{
          height: '100%',
        }}
      >
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            component="span"
            startIcon={<CloudUploadIcon />}
            size="large"
          >
            Upload (.jpg or .png)
          </Button>
        </label>
        <Typography
          variant="p"
          margin={1}
          sx={{
            color: '#1976D2',
          }}
        >
          {file ? file.name : 'File Not Submitted'}
        </Typography>
        <Divider />
      </Box>
    </>
  );
};

// Widget to Feedback Inform
const InformFeedback = ({ feedback }) => {
  return (
    <>
      {/* Title: Submission Status */}
      <Typography variant="h6" margin={1}>
        Submission Status
      </Typography>

      <Divider />

      {/* Content: Feedback */}
      {feedback ? (
        <Alert severity={feedback.severity}> {feedback.message} </Alert>
      ) : (
        <></>
      )}
    </>
  );
};
//
const InformTemplates = () => {
  const [templates, setTemplates] = useState(null);
  const { user } = useAuth();
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await getMainConfig();
        setTemplates(response?.data?.data?.posterTemplatesLinks || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchTemplates();
  }, []);

  return (
    <>
      {/* Title: Poster Templates */}
      <Typography variant="h6" margin={1}>
        Poster Templates
      </Typography>

      <Divider />

      {/* Body: Descriptions */}
      <ul>
        <li>
          <Typography variant="subtitle1">
            You are required to use the following poster template.
          </Typography>
        </li>
        <li>
          <Typography variant="subtitle1">
            Please download the file to your local computer and add your poster
            content.
          </Typography>
        </li>
        <li>
          {' '}
          <Typography variant="subtitle1">
            Please do not change the template set-up or structure.
          </Typography>
        </li>
      </ul>
      <Divider sx={{ marginY: 1 }} />
      {/* Title: Links */}
      <Typography variant="h6">Template for your Major & Course</Typography>

      {/* LIST: Template Links */}
      {templates &&
        templates
          .filter(
            (template) =>
              template.name ===
              `${user?.department} POSTER TEMPLATE (${user?.course})`
          )
          .map((template) => (
            <Box key={template.name + 'box'} display={'flex'}>
              <ul>
                <li>
                  <Link
                    key={template.name}
                    to={template.link}
                    target="_blank"
                    style={{
                      padding: '1px',
                    }}
                  >
                    {template.name}
                  </Link>
                </li>
              </ul>
            </Box>
          ))}
    </>
  );
};

// Widget for General Poster Information
const InformPoster = ({ project, dueDates }) => {
  return (
    <>
      {/* Title: Poster Information */}
      <Typography variant="h6" margin={1}>
        Poster Deliverables
      </Typography>

      <Divider sx={{ marginBottom: 1 }} />

      <Task
        title="Draft: Poster File (.pdf)"
        subtitle={`Due Date: ${formatDate(dueDates?.posterLink, true, true)}`}
        isCompleted={project?.data?.posterLink.length > 0 || false}
      >
        <ul>
          <li> Submit a PDF of your poster using the upload poster form.</li>
          <li>Verify that the link works upon submission.</li>
        </ul>
      </Task>
      <Task
        title="Draft: Poster Image"
        subtitle={`Due Date: ${formatDate(dueDates?.posterImage, true, true)}`}
        isCompleted={
          (project?.data?.posterImage.length > 0 &&
            !project?.data.posterImage.includes('Default')) ||
          false
        }
      >
        <ul>
          <li>
            Submit an image of your poster using the upload poster image form.
          </li>
          <li>Image should be either .png or .jpg format</li>
          <li>Verify that the image is clear and legible after submission.</li>
        </ul>
      </Task>
      <Task
        title="Final: Poster File (.pdf)"
        subtitle={`Due Date: ${formatDate(
          dueDates?.posterLinkFinal,
          true,
          true
        )}`}
        isCompleted={
          (project?.data?.posterLinkStatus === 'FINAL' &&
            project?.data?.posterLink.length > 0) ||
          false
        }
      >
        <ul>
          <li> Using the toggle switch, mark the submission as FINAL.</li>
          <li> Submit a PDF of your poster using the upload poster form.</li>
          <li>Verify that the link works after submission.</li>
        </ul>
      </Task>
      <Task
        title="Final: Poster Image"
        subtitle={`Due Date: ${formatDate(
          dueDates?.posterImageFinal,
          true,
          true
        )}`}
        isCompleted={
          (project?.data?.posterImageStatus === 'FINAL' &&
            project?.data?.posterImage.length > 0 &&
            !project?.data.posterImage.includes('Default')) ||
          false
        }
      >
        <ul>
          <li> Using the toggle switch, mark the submission as FINAL.</li>
          <li>
            Submit an image of your poster using the upload poster image form.
          </li>
          <li>Image should be either .png or .jpg format</li>
          <li>Verify that the image is clear and legible after submission.</li>
        </ul>
      </Task>
    </>
  );
};

// Widget for No Project Selected Notice
const InformNull = () => {
  return (
    <>
      {/* Title: No Project Notice */}
      <Typography variant="h6" margin={1}>
        No Project Selected Notice
      </Typography>

      <Divider />

      {/* Paragraph: No Selected Project */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 1,
        }}
      >
        No project is currently selected.
      </Typography>

      {/* Due Date */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 3,
          color: '#226E93',
        }}
      >
        Please select a project to submit a poster.
      </Typography>

      {/* Title: Resolution Options*/}
      <>
        <Typography variant="h6" margin={1}>
          Options for Resolution
        </Typography>
        <Divider />
        <ul style={{ marginTop: 4 }}>
          <li>Create a Project in the Dashboard Page.</li>
          <li>Select an Existing Project from the Project Dropdown Menu.</li>
        </ul>
      </>
    </>
  );
};

// Page that shows when PID != 0
const ExistingProjectPoster = () => {
  const [feedback, setFeedback] = useState(null);
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('/student/dashboard/project/0');
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [navigate, pid]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}
            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Poster Submission
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: Poster Template Links*/}
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformTemplates />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Poster Information*/}
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPoster project={project} dueDates={config?.dueDates} />
              </Paper>
            </Grid>

            {/*Inner Grid Item 6: SUBMIT POSTER LINK*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitPosterLink
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: DISPLAY POSTER LINK*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPosterLink
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                  dueDate={formatDate(
                    determineDate(
                      config?.dueDates?.posterLink,
                      config?.dueDates?.posterLinkFinal
                    )
                  )}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 8: SUBMIT POSTER IMAGE*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitPosterImage
                  project={project}
                  setFeedback={setFeedback}
                  setProject={setProject}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 7: DISPLAY POSTER IMAGE*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformPosterImage
                  project={project}
                  setProject={setProject}
                  dueDate={formatDate(
                    determineDate(
                      config?.dueDates?.posterImage,
                      config?.dueDates?.posterImageFinal
                    )
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when PID == 0
const NewProjectPoster = () => {
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}
            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Poster Submission
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: No Project Selected Notice*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformNull />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Poster Information*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformPoster />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Poster Template Links*/}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <InformTemplates />
              </Paper>
            </Grid>
            {/* Inner Grid Ends */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectPoster() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard Page={ExistingProjectPoster} PageName="(S) Dashboard - Poster" />
  ) : (
    <Dashboard Page={NewProjectPoster} PageName="(S) Dashboard - Poster" />
  );
}
