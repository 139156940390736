import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Admin/Dashboard.js';
import { useState, useEffect } from 'react';
import { Styles } from '../../../styles/global.js';
import {
  addProjectToArchivedProjects,
  createJudgeAssignments,
  getArchivedProjects,
  getAssignemnts,
  getProjects,
  getRubrics,
} from '../../../utils/API.js';
import 'chart.js/auto';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import { Scoreboard } from '@mui/icons-material';
import useFeedback from '../../../utils/FeedbackContext/useFeedback.js';
import AdvancedModal from '../../../components/AdvancedModal.js';

function Judge({ project, label, judge, isAlreadyAssigned, triggerRefresh }) {
  const [open, setOpen] = useState(false);
  const [rubric, setRubric] = useState(null);
  const [rubrics, setRubrics] = useState([]);

  const { notifyError, notifySuccess } = useFeedback();

  const handleCreate = async () => {
    try {
      await createJudgeAssignments({
        rubric,
        judge: judge,
        project: project?.id,
      });

      notifySuccess('Assignment Created Successfully');
      setRubric(null);
      setOpen(false);
      triggerRefresh();
    } catch (error) {
      console.error('Error Creating Assignment:', error);
      notifyError('Error Creating Assignment, Please Try Again');
    }
  };

  useEffect(() => {
    async function fetchRubrics() {
      try {
        const rubrics = await getRubrics('');
        const filteredRubrics = rubrics?.data?.data?.filter(
          (rubric) =>
            rubric?.type === 'Department' &&
            rubric.department === project?.department &&
            rubric.course === project?.course
        );
        setRubrics(filteredRubrics);

        if (filteredRubrics?.length) {
          setRubric(filteredRubrics[0]?._id);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchRubrics();
  }, []);

  return (
    <Box>
      <Chip
        label={label}
        color={isAlreadyAssigned ? 'error' : 'default'}
        variant="outlined"
        sx={
          isAlreadyAssigned && {
            textDecoration: 'line-through',
            opacity: 0.6,
          }
        }
        onClick={isAlreadyAssigned ? undefined : () => setOpen(true)}
      />
      {open && (
        <AdvancedModal
          open={open}
          handleClose={() => setOpen(false)}
          title="Assign Judge (Best In Department)"
          handleSave={handleCreate}
        >
          <Typography variant="h6">
            <strong>Project Title</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {project?.title}
          </Typography>
          <Typography variant="h6">
            <strong>Department & Course</strong>
          </Typography>
          <Typography variant="body1">
            {project?.department} - {project?.course}
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="h6">
            <strong>Selected Judge</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {label}
          </Typography>

          {!!rubrics?.length ? (
            <Box>
              <Typography variant="h6">
                <strong>Selected Rubric</strong>
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <Select
                  value={rubric}
                  onChange={(e) => setRubric(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {rubrics?.map((rubric) => (
                    <MenuItem key={rubric._id} value={rubric._id}>
                      <Box display="flex" gap={2} alignItems={'center'}>
                        {rubric?.title}
                        <Chip label={rubric?.type} />
                        {rubric?.course !== 'N/A' && (
                          <Chip label={rubric?.course} />
                        )}
                        {rubric?.department !== 'N/A' && (
                          <Chip label={rubric?.department} />
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <>No rubrics available...</>
          )}
        </AdvancedModal>
      )}
    </Box>
  );
}

function getBidsTable(bids) {
  if (!bids || !bids?.length) {
    return [];
  }

  return bids?.map((bid) => {
    return {
      id: bid._id,
      title: bid.title,
      department: bid.department,
      course: bid.course,
      numOfDepartmentAssignments: bid?.assignments?.filter(
        (assignment) => assignment?.rubricCopy?.type === 'Department'
      )?.length,
      bids:
        bid.projectBidsDescription
          ?.filter((bid) => bid?.department !== 'N/A' && bid?.course !== 'N/A')
          ?.map((bid) => bid.fullName)
          .join(', ') || '',
      raw: bid,
    };
  });
}

function ViewBids({
  bids,
  setBids,
  refreshArchived,
  triggerRefresh,
  isArchived = false,
}) {
  const paginationModel = { page: 0, pageSize: 10 };
  const [selectedRows, setSelectedRows] = useState([]);
  const { notifySuccess, notifyError } = useFeedback();

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel); // selectionModel contains selected row IDs
  };

  const onArchive = async () => {
    try {
      await addProjectToArchivedProjects(selectedRows);
      await refreshArchived();
      setSelectedRows([]);
      notifySuccess('Projects archived successfully');
    } catch (error) {
      console.error(error);
      notifyError('Failed to archive projects');
    }
  };

  const renderJudges = (judges, assignments, project) => {
    if (!judges || !judges?.length) {
      return <>No Judges</>;
    }

    return judges?.map((judge) => {
      const isAlreadyAssigned = assignments?.some(
        (assignment) =>
          assignment?.rubricCopy?.type === 'Department' &&
          assignment?.judge === judge?.id
      );
      return (
        <Judge
          label={`${judge?.fullName} (${judge?.department} - ${judge?.course})`}
          isAlreadyAssigned={isAlreadyAssigned}
          key={judge?.id}
          judge={judge?.id}
          project={project}
          triggerRefresh={triggerRefresh}
        />
      );
    });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      sortable: true,
      filterable: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
      sortable: true,
      filterable: true,
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 150,
      type: 'singleSelect',
      valueOptions: ['CIS', 'IMSE', 'ECE', 'ME'],
    },
    {
      field: 'numOfDepartmentAssignments',
      headerName: 'Assignments',
      width: 150,
      filterable: false,
      renderCell: (params) => {
        const numOfDepartmentAssignments =
          params?.row?.raw?.assignments?.filter(
            (assignment) => assignment?.rubricCopy?.type === 'Department'
          )?.length || 0;
        return (
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={1}
            alignItems={'center'}
            marginY={1}
          >
            <Chip
              label={`${numOfDepartmentAssignments} Assignments`}
              color="success"
              icon={<Scoreboard />}
              variant="outlined"
            />
          </Box>
        );
      },
    },
    {
      field: 'bids',
      headerName: 'Judge Bids',
      flex: 1,
      renderCell: (params) => {
        const raw = params?.row?.raw;
        const bids = raw?.projectBidsDescription;
        return (
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={1}
            alignItems={'center'}
            marginY={1}
          >
            {renderJudges(bids, raw?.assignments, raw)}
          </Box>
        );
      },
      type: 'singleSelect',
      valueOptions: ['PENDING', 'COMPLETED'],
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   headerAlign: 'center',
    //   flex: 0,
    //   filterable: false,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         alignItems={'center'}
    //         display="flex"
    //         height={'100%'}
    //         width={'100%'}
    //         justifyContent={'center'}
    //       >
    //         <MoreVert
    //           onClick={() => {
    //             setAssignment(params?.row?.raw);
    //             openModal();
    //           }}
    //           sx={{
    //             cursor: 'pointer',
    //           }}
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  const transformedBids = getBidsTable(bids);

  return (
    !!transformedBids && (
      <>
        <Box display={'flex'} marginBottom={2} justifyContent={'space-between'}>
          <Typography>{`${
            isArchived ? 'Archived' : 'Active'
          } Bids`}</Typography>

          {/* {!isArchived && !!selectedRows?.length && (
            <Button
              variant="contained"
              onClick={() => {
                onArchive();
              }}
            >
              {selectedRows?.length} Archive{' '}
            </Button>
          )} */}
        </Box>
        <DataGrid
          rows={transformedBids}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[10, 25, 50, 100]}
          components={{
            Toolbar: () => (
              <GridToolbar>
                <GridToolbarExport />
              </GridToolbar>
            ),
          }}
          sx={{
            height: 500, // Ensure it has a height
            border: '1px solid #ddd',
            borderRadius: 2,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '1rem',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-root': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.9rem',
              borderBottom: '1px solid #eee',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: '#f4f4f4',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#e3f2fd',
            },
          }}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          getRowHeight={() => 'auto'} // Enable dynamic row height
          // onRowSelectionModelChange={handleSelectionChange}
        />
      </>
    )
  );
}

function Bids() {
  let [bids, setBids] = useState({});
  let [archived, setArchived] = useState({});

  async function fetchData() {
    try {
      let bidsResults = await getProjects(`year=${new Date().getFullYear()}`);
      bidsResults = bidsResults?.data?.data
        ?.filter((bid) => bid?.projectBids?.length > 0)
        .filter((bid) =>
          bid?.projectBidsDescription?.some((bid) => {
            return bid?.department !== 'N/A' && bid?.course !== 'N/A';
          })
        );

      setBids(bidsResults);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchArchived() {
      try {
        let archived = await getArchivedProjects();
        archived = archived?.data?.data;
        setArchived(archived);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    fetchArchived();
  }, []);

  const refreshArchived = async () => {
    try {
      let archived = await getArchivedProjects();
      archived = archived?.data?.data;
      setArchived(archived);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Judge Bids (Best In Department)
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: View Non-Archived Bids*/}
            {bids && bids?.length > 0 && (
              <>
                <Grid item lg={12} sm={12} xs={12}>
                  <Paper {...Styles.dashboardContentWidget('auto')}>
                    <ViewBids
                      bids={bids?.filter((bid) => {
                        return (
                          !archived?.includes(bid?._id) &&
                          bid?.projectBids?.length > 0
                        );
                      })}
                      setBids={setBids}
                      refreshArchived={refreshArchived}
                      triggerRefresh={fetchData}
                    />
                  </Paper>
                </Grid>
                {/* <Grid item lg={12} sm={12} xs={12}>
                  <Paper {...Styles.dashboardContentWidget('auto')}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography component="span">
                          View Archived Bids
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ViewBids
                          bids={bids?.filter((bid) => {
                            return (
                              archived?.includes(bid?._id) &&
                              bid?.projectBids?.length > 0
                            );
                          })}
                          setBids={setBids}
                          refreshArchived={refreshArchived}
                          isArchived={true}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </Grid> */}
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardBids() {
  return <Dashboard Page={Bids} PageName="(A) Dashboard - Judge Bids" />;
}
