import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../utils/AuthContext.js';
import NavBar from '../../../components/AttendeeNavBar.js';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Alert,
  Autocomplete,
  Tooltip,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { verifyTokenLink } from '../../../utils/API.js';
import LoadingPage from '../../Loading.js';
import { academicTitles } from '../../../utils/formatAttributes.js';
import PasswordField from '../../../components/Password.js';
import InputMask from 'react-input-mask';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" to="/">
        University of Michigan-Dearborn
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

function SignUp({ title, profile }) {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    firstPassword: '',
    secondPassword: '',
    ...profile, // course, email, department, token, date
  });

  const { registerJudge, registerError } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    const u = await registerJudge({
      ...form,
      fullName: form.firstName + ' ' + form.lastName,
      umichID: Number(form.umichID),
      password: form.firstPassword,
    });
    if (u) {
      if (u.success && u.data.role === 'JUDGE') {
        navigate('/judge/dashboard');
        return;
      }
      if (u.success && u.data.role === 'FACULTY') {
        navigate(
          `/faculty/dashboard?department=${u.data.department}&course=${
            u.data.course
          }&year=${new Date().getFullYear()}`
        );
        return;
      }
      if (u.success && u.data.role === 'ADMIN') {
        navigate(`/admin/dashboard?year=${new Date().getFullYear()}`);
        return;
      }
    }
  };

  const invalidInput = () => {
    const temp = { ...form, fullName: 'temp', phoneNumber: 'temp' };

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length > 90
      )
    ) {
      return true;
    }

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length === 0
      )
    ) {
      return true;
    }

    if (
      form.firstPassword !== form.secondPassword ||
      form.firstPassword.length === 0
    ) {
      return true;
    }
    return false;
  };

  const roleLink = () => {
    if (title === 'Judge') {
      return 'judge';
    } else {
      return 'faculty';
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'teal' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {title} Sign up
          </Typography>
          {registerError && (
            <Alert severity="error" sx={{ my: 3 }}>
              {registerError}
            </Alert>
          )}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={form.firstName}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={form.lastName}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={academicTitles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="prefix"
                      label="Prefix"
                      name="prefix"
                      value={form.prefix}
                    />
                  )}
                  onChange={(e, value) => setForm({ ...form, prefix: value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="department"
                  label="Department"
                  name="department"
                  value={form?.department === 'N/A' ? 'CECS' : form.department}
                  placeholder={form.department}
                  disabled
                />
              </Grid>

              {title === 'Judge' && (
                <Grid item xs={12}>
                  <Autocomplete
                    options={interests}
                    renderInput={(params) => (
                      <TextField {...params} label="Interest" />
                    )}
                    value={form.interest}
                    onChange={(e, value) =>
                      setForm({ ...form, interest: value })
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={form.email}
                  placeholder={form.email}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  value={form.firstPassword}
                  onChange={(e) =>
                    setForm({ ...form, firstPassword: e.target.value })
                  }
                  autocomplete="new-password"
                />
              </Grid>

              <Grid item xs={12}>
                <PasswordField
                  required
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  id="password"
                  value={form.secondPassword}
                  onChange={(e) =>
                    setForm({ ...form, secondPassword: e.target.value })
                  }
                />
              </Grid>

              {title === 'Judge' && (
                <Grid item xs={12}>
                  <InputMask
                    mask="(999) 999-9999"
                    maskChar=" "
                    value={form.phoneNumber}
                    onChange={(e) =>
                      setForm({ ...form, phoneNumber: e.target.value })
                    }
                  >
                    {() => (
                      <Tooltip title="We will not use this number for any purpose other than to contact you if needed during the event. (Optional)">
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          id="phoneNumber"
                          fullWidth
                        />
                      </Tooltip>
                    )}
                  </InputMask>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl disabled>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Course
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.course}
                  >
                    <FormControlLabel
                      value="SD1"
                      control={<Radio />}
                      label="SD1"
                    />
                    <FormControlLabel
                      value="SD2"
                      control={<Radio />}
                      label="SD2"
                    />
                    <FormControlLabel
                      value="N/A"
                      control={<Radio />}
                      label="CECS"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
              disabled={!!invalidInput()}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={`/${roleLink()}/login`} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}

function InvalidLink() {
  const navigate = useNavigate();
  useEffect(() => {
    async function redirect() {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      navigate('/');
    }
    redirect();
  });
  return (
    <div>
      <h3>Invalid Link, Reach Out to Adminstrators if link is broken.</h3>
      <LoadingPage />
    </div>
  );
}

export default function JudgeSignUp({ title }) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  const { token } = useParams();

  // Verify Link
  useEffect(() => {
    async function fetchVerify() {
      try {
        const response = await verifyTokenLink(token);
        if (response.data.success === true) {
          setProfile(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        if (error?.response) {
          setProfile(null);
          setLoading(false);
        }
        console.error('Error verifying the link:', error);
      }
    }
    fetchVerify();
  }, [token]);

  const content = () => {
    return profile ? (
      <SignUp title={title} profile={profile} />
    ) : (
      <InvalidLink />
    );
  };

  return <div>{loading ? LoadingPage() : content()}</div>;
}

// CIS , IMSE, ME, ECE
const interests = [
  'Artificial Intelligence',
  'Cybersecurity',
  'Data Science',
  'Machine Learning',
  'Software Engineering',
  'Web Development',
  'Electrical Engineering',
  'Computer Engineering',
  'Mechanical Engineering',
  'Industrial Engineering',
  'Systems Engineering',
  'Software Development',
  'Information Systems',
  'Information Technology',
  'Computer Networking',
  'Embedded Systems',
  'Game Development',
  'Project Management',
  'Quality Assurance',
  'Supply Chain Management',
  'Operations Management',
  'Business Analysis',
  'Analog Circuits',
  'Digital Circuits',
  'Control Systems',
  'Other',
];
