import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Student/Dashboard.js';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';

import { TextField, Typography, Divider, Button } from '@mui/material';
import {
  getMainConfig,
  getProject,
  updateDisplayRequest,
} from '../../../utils/API.js';
import { formatDate } from '../../../utils/dates.js';
import { InformProjectDisplayRequest } from '../../../components/Student/Widgets';
import useFeedback from '../../../utils/FeedbackContext/useFeedback.js';
import Task from '../../../components/Task.js';

// Form: Submit Abstract
const SubmitAbstract = ({ setFeedback, setProject }) => {
  const { pid } = useParams();
  const [dr, setDr] = useState('');
  const { notifySuccess, notifyError } = useFeedback();

  const handleSubmission = async () => {
    try {
      const response = await updateDisplayRequest(pid, dr);

      if (response.data.success) {
        setFeedback({ severity: 'success', message: response.data.message });
        notifySuccess('Display Request submitted successfully');
        try {
          const response = await getProject(pid);
          setProject(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFeedback({
        severity: 'error',
        message: error.response.data.errMessage,
      });
      notifyError(
        error?.response?.data?.errMessage || 'Error submitting display request'
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Title; Submit Abstract */}
        <Typography variant="h6" margin={1}>
          Submit Additional Display Requests
        </Typography>

        <Divider />

        {/* Button: Submit Abstract */}
        <Button
          variant="outlined"
          disabled={dr.length === 0}
          onClick={handleSubmission}
          sx={{ marginBottom: 1 }}
        >
          Submit
        </Button>
      </Box>

      <Divider />

      {/* Input: Abstract Submit */}
      <TextField
        {...StylesForm.input(
          'submitDisplayRequest',
          'Include any additional display requests here'
        )}
        InputProps={{
          rows: 9,
          multiline: true,
          inputComponent: 'textarea',
          maxLength: 600,
        }}
        value={dr}
        onChange={(e) => setDr(e.target.value)}
      />
    </>
  );
};

// Widget to Feedback Inform
const InformRules = () => {
  return (
    <>
      {/* Title: Submission Status */}
      <Typography variant="h6" margin={1}>
        Project Display Request Form
      </Typography>
      <Divider />
      {/* Content: Feedback */}
      <Typography variant="p" margin={1}>
        "This request applies only to students in the Senior Design 2
        competition. Each Senior Design 2 team will have access to (1) an easel
        stand to display the team's printed poster and (2) a display table.
        Please enter below any additional display request needed by your team.
        <strong> Any additional request is subject to approval.</strong>"
      </Typography>
    </>
  );
};

// Widget to provide basic guidelines for the abstract submissions.
const InformAbstractGuidelines = ({ project, dueDate }) => {
  return (
    <>
      {/* Title: Abstract Information */}
      <Typography variant="h6" margin={1}>
        Display Request Guidelines
      </Typography>

      <Divider sx={{ marginBottom: 1 }} />

      <Task
        title="Display Request Info"
        isCompleted={project?.data?.displayRequest?.length > 0 || false}
        subtitle={`Due date: ${formatDate(dueDate, true, true)}`}
      >
        <p>
          Optional: "This request applies only to students in the Senior Design
          2 competition. Each Senior Design 2 team will have access to (1) an
          easel stand to display the team's printed poster and (2) a display
          table. Please enter below any additional display request needed by
          your team. Any additional request is subject to approval."
        </p>
      </Task>
    </>
  );
};

// Widget for No Project Selected Notice
const InformNull = () => {
  return (
    <>
      {/* Title: No Project Notice */}
      <Typography variant="h6" margin={1}>
        No Project Selected Notice
      </Typography>

      <Divider />

      {/* Paragraph: No Selected Project */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 1,
        }}
      >
        No project is currently selected.
      </Typography>

      {/* Due Date */}
      <Typography
        {...StylesForm.text('p')}
        sx={{
          marginBottom: 3,
          color: '#226E93',
        }}
      >
        Please select a project to submit an abstract.
      </Typography>

      {/* Title: Resolution Options*/}
      <>
        <Typography variant="h6" margin={1}>
          Options for Resolution
        </Typography>
        <Divider />
        <ul style={{ marginTop: 4 }}>
          <li>Create a Project in the Dashboard Page.</li>
          <li>Select an Existing Project from the Project Dropdown Menu.</li>
        </ul>
      </>
    </>
  );
};

// Page that shows when PID NOT == 0
const ExistingProjectDisplayRequest = () => {
  const [feedback, setFeedback] = useState(null);
  const [project, setProject] = useState(null);
  const [config, setConfig] = useState(null);
  const { pid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProject(pid);
        setProject(response.data);
        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        navigate('/student/dashboard/project/0/abstract');
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [pid, navigate]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Additional Display Request
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Abstract Information*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformRules />
              </Paper>
            </Grid>

            {/*Inner Grid Item 3: General Abstract Information*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformAbstractGuidelines
                  project={project}
                  dueDate={config?.dueDates?.displayRequest}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 4: Submit Abstract Form*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <SubmitAbstract
                  project={project}
                  setProject={setProject}
                  feedback={feedback}
                  setFeedback={setFeedback}
                />
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: THE Display Request*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformProjectDisplayRequest
                  project={project}
                  dueDate={formatDate(config?.dueDates?.displayRequest)}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

// Page that shows when PID == 0
const NewProjectRequest = () => {
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}

          <Grid container spacing={4}>
            {/*Inner Grid Begins*/}
            {/*Inner Grid Item 1: Project Abstract Form Title*/}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>
                Project Display Request
              </Paper>
            </Grid>

            {/*Inner Grid Item 2: No Project Selected Notice*/}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget()}>
                <InformNull />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default function ProjectDisplayRequest() {
  const { pid } = useParams();

  return Number(pid) !== 0 ? (
    <Dashboard
      Page={ExistingProjectDisplayRequest}
      PageName={'(S) Dashboard - Display Request'}
    />
  ) : (
    <Dashboard
      Page={NewProjectRequest}
      PageName={'(S) Dashboard - Display Request'}
    />
  );
}
