import React, { createContext, useReducer, useContext } from 'react';

// Initial state for the modal
const initialState = {
  open: false,
  tab: 0,
  width: { xs: '90vw', sm: '80vw', md: '70vw', lg: '60vw', xl: '50vw' },
  assignment: null,
};

// Modal actions
const actionTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_ASSIGNMENT: 'SET_ASSIGNMENT',
  SET_WIDTH: 'SET_WIDTH',
  SET_TAB: 'SET_TAB',
};

// Reducer to manage modal state
function modalReducer(state, action) {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return { ...state, open: true };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        assignment: null,
        tab: 0,
        width: { xs: '90vw', sm: '80vw', md: '70vw', lg: '60vw', xl: '50vw' },
      };
    case actionTypes.SET_ASSIGNMENT:
      return { ...state, assignment: action.payload };
    case actionTypes.SET_WIDTH:
      return { ...state, width: action.payload };
    case actionTypes.SET_TAB:
      return { ...state, tab: action.payload };
    default:
      return state;
  }
}

// Create the context
const ModalContext = createContext();

// Custom hook to use modal context
export function useModal() {
  return useContext(ModalContext);
}

// ModalProvider component to wrap around your app or components that need modal state
export function ModalProvider({ children }) {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  // Modal actions
  const openModal = () => dispatch({ type: actionTypes.OPEN_MODAL });
  const closeModal = () => dispatch({ type: actionTypes.CLOSE_MODAL });
  const setAssignment = (assignment) =>
    dispatch({ type: actionTypes.SET_ASSIGNMENT, payload: assignment });
  const setTab = (stage) =>
    dispatch({ type: actionTypes.SET_TAB, payload: stage });
  const setWidth = (width) =>
    dispatch({ type: actionTypes.SET_WIDTH, payload: width });

  return (
    <ModalContext.Provider
      value={{
        state,
        openModal,
        setAssignment,
        closeModal,
        setTab,
        setWidth,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
