import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Dashboard from '../../../components/Admin/Dashboard';
import { Styles } from '../../../styles/global';
import { useEffect, useState } from 'react';
import {
  getInvites,
  getUsers,
  inviteJudgeOrFaculty,
  objectToQueryString,
} from '../../../utils/API';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UsersTable from '../../../components/UsersTable';
import { Tabs, Tab } from '@mui/material';
import InvitesTable from '../../../components/InvitesTable';
import { useAuth } from '../../../utils/AuthContext';
import { useFeedback } from '../../../utils/FeedbackContext';
import PasswordField from '../../../components/Password';
import { academicTitles } from '../../../utils/formatAttributes';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function CreateFaculty() {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    role: 'ADMIN',
    year: new Date().getFullYear(),
    firstPassword: '',
    secondPassword: '',
    department: 'N/A',
    course: 'N/A',
    bypassCookie: true,
  });
  const { registerFaculty, registerError } = useAuth();
  const { notifySuccess, notifyError } = useFeedback();
  const handleSubmit = async (event) => {
    const u = await registerFaculty({
      ...form,
      fullName: form.firstName + ' ' + form.lastName,
      umichID: Number(form.umichID),
      password: form.firstPassword,
    });
    if (u) {
      if (u.success) {
        notifySuccess('User created successfully');
      } else {
        notifyError('Error creating user');
      }
    }
  };

  const invalidInput = () => {
    const temp = { ...form, fullName: 'temp' };

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length > 40
      )
    ) {
      return true;
    }

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length === 0
      )
    ) {
      return true;
    }

    if (
      form.firstPassword !== form.secondPassword ||
      form.firstPassword.length === 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Faculty Creation Form
          </Typography>
          {registerError && (
            <Alert severity="error" sx={{ my: 3 }}>
              {registerError}
            </Alert>
          )}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={form.firstName}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={form.lastName}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={academicTitles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="prefix"
                      label="Prefix"
                      name="prefix"
                      value={form.prefix}
                    />
                  )}
                  onChange={(e, value) => setForm({ ...form, prefix: value })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  value={form.firstPassword}
                  onChange={(e) =>
                    setForm({ ...form, firstPassword: e.target.value })
                  }
                  autoComplete="new-password"
                />
              </Grid>

              <Grid item xs={12}>
                <PasswordField
                  required
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  id="password"
                  value={form.secondPassword}
                  onChange={(e) =>
                    setForm({ ...form, secondPassword: e.target.value })
                  }
                  autoComplete="new-password"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Role
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.role}
                    onChange={(e) => setForm({ ...form, role: e.target.value })}
                  >
                    <FormControlLabel
                      value="FACULTY"
                      control={<Radio />}
                      label="Faculty"
                    />
                    <FormControlLabel
                      value="ADMIN"
                      control={<Radio />}
                      label="Admin"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Department
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.department}
                    onChange={(e) =>
                      setForm({ ...form, department: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="CIS"
                      control={<Radio />}
                      label="CIS"
                    />
                    <FormControlLabel
                      value="ECE"
                      control={<Radio />}
                      label="ECE"
                    />
                    <FormControlLabel
                      value="IMSE"
                      control={<Radio />}
                      label="IMSE"
                    />
                    <FormControlLabel
                      value="ME"
                      control={<Radio />}
                      label="ME"
                    />
                    <FormControlLabel
                      value="N/A"
                      control={<Radio />}
                      label="CECS"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Course
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.course}
                    onChange={(e) =>
                      setForm({ ...form, course: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="SD1"
                      control={<Radio />}
                      label="SD1"
                    />
                    <FormControlLabel
                      value="SD2"
                      control={<Radio />}
                      label="SD2"
                    />
                    <FormControlLabel
                      value="BOTH"
                      control={<Radio />}
                      label="BOTH"
                    />
                    <FormControlLabel
                      value="N/A"
                      control={<Radio />}
                      label="CECS"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
              disabled={!!invalidInput()}
            >
              Create User
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

function InviteForm({ setInvites }) {
  const { notifySuccess, notifyError } = useFeedback();
  const [form, setForm] = useState({
    email: '',
    role: '',
    department: '',
    course: '',
    emailSent: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseInvite = await inviteJudgeOrFaculty(form);
      const response = await getInvites();
      setForm({
        email: '',
        role: '',
        department: '',
        course: '',
        emailSent: '',
      });

      setInvites(response?.data?.data || []);
      notifySuccess(responseInvite?.data?.message || 'Invite sent');
    } catch (error) {
      console.log(error);
      notifyError('Error sending invite');
    }
  };

  return (
    <Paper>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="form"
            noValidate
            sx={{ mt: 3 }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={form.email}
                  onChange={(e) =>
                    setForm({ ...form, email: e.target.value.toLowerCase() })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Role
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.role}
                    onChange={(e) => setForm({ ...form, role: e.target.value })}
                  >
                    <FormControlLabel
                      value="JUDGE"
                      control={<Radio />}
                      label="Judge"
                    />
                    <FormControlLabel
                      value="FACULTY"
                      control={<Radio />}
                      label="Faculty"
                    />
                    <FormControlLabel
                      value="ADMIN"
                      control={<Radio />}
                      label="Admin"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Department
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.department}
                    onChange={(e) =>
                      setForm({ ...form, department: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="CIS"
                      control={<Radio />}
                      label="CIS"
                    />
                    <FormControlLabel
                      value="ECE"
                      control={<Radio />}
                      label="ECE"
                    />
                    <FormControlLabel
                      value="IMSE"
                      control={<Radio />}
                      label="IMSE"
                    />
                    <FormControlLabel
                      value="ME"
                      control={<Radio />}
                      label="ME"
                    />

                    <FormControlLabel
                      value="N/A"
                      control={<Radio />}
                      label="CECS"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Course
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.course}
                    onChange={(e) =>
                      setForm({ ...form, course: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="SD1"
                      control={<Radio />}
                      label="SD1"
                    />
                    <FormControlLabel
                      value="SD2"
                      control={<Radio />}
                      label="SD2"
                    />
                    {form?.role !== 'JUDGE' && (
                      <>
                        <FormControlLabel
                          value="BOTH"
                          control={<Radio />}
                          label="BOTH"
                        />
                      </>
                    )}
                    <FormControlLabel
                      value="N/A"
                      control={<Radio />}
                      label="CECS"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Should the website send an email to the prospective user?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.emailSent}
                    onChange={(e) =>
                      setForm({ ...form, emailSent: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value={'YES'}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={'NO'}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
              disabled={
                form.email.length === 0 ||
                form.role.length === 0 ||
                form.department.length === 0 ||
                form.course.length === 0 ||
                form.emailSent === undefined
              }
            >
              Invite
            </Button>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
}

function UsersTabs({ users, invites, setInvites, flag, setFlag }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Users tabs">
          <Tab label="View Users" />
          <Tab label="Invite Users" />
          <Tab label="Create Faculty" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UsersTable
          rows={users}
          adminMode={true}
          flag={flag}
          setFlag={setFlag}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div>
          <Paper {...Styles.dashboardPageWidget()}>Account Invite Form</Paper>
          <Divider />
          <InviteForm setInvites={setInvites} />
          <Divider />
        </div>
        <div>
          <Paper {...Styles.dashboardPageWidget()}>Pending Invites</Paper>
          <Divider />
          <InvitesTable rows={invites} setInvites={setInvites} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Paper sx={{ padding: 1 }}>
          <CreateFaculty />
        </Paper>
      </CustomTabPanel>
    </Box>
  );
}

function Users() {
  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [searchParams] = useSearchParams();
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const fetchStudents = async () => {
      const params = {};
      if (searchParams.get('department')) {
        params.department = searchParams.get('department');
      }
      if (searchParams.get('year')) {
        params.year = searchParams.get('year');
      }

      if (searchParams.get('course')) {
        params.course = searchParams.get('course');
      }

      const response = await getUsers(objectToQueryString(params));
      setUsers(response?.data?.data || []);
    };
    const fetchInvites = async () => {
      const response = await getInvites();
      setInvites(response?.data?.data || []);
    };
    try {
      fetchStudents();
      fetchInvites();
    } catch (error) {
      console.log(error);
    }
  }, [searchParams, flag]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer()}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Users</Paper>
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Box {...Styles.dashboardContentWidget('auto')}>
                <UsersTabs
                  users={users}
                  invites={invites}
                  setInvites={setInvites}
                  flag={flag}
                  setFlag={setFlag}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardUsers() {
  return <Dashboard Page={Users} PageName={'(A) Dashboard - Users'} />;
}
