import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Dashboard from '../../../../components/Judge/Dashboard';
import { Styles } from '../../../../styles/global';
import { useEffect, useRef, useState } from 'react';
import { getProjects, objectToQueryString } from '../../../../utils/API';
import { useSearchParams } from 'react-router-dom';
import ProjectsView from '../../../../components/Views';
import { useAuth } from '../../../../utils/AuthContext';
import Filters from '../../../../components/Filters';
import projectsFiltersSchema from '../../../../utils/FiltersSchema/JudgeProjects';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef(null); // Create a ref for the top of the page
  const { user } = useAuth();

  useEffect(() => {
    if (!searchParams.get('limit')) {
      setSearchParams(`${searchParams.toString()}&limit=20`);
    }
    if (!searchParams.get('page')) {
      setSearchParams(`${searchParams.toString()}&page=1`);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    async function fetchProjects(params) {
      try {
        const projectsResponse = await getProjects(params);
        setProjects(projectsResponse?.data || []);
      } catch (error) {
        console.log(error);
      }
    }

    let department = {};
    let course = {};
    if (user?.department === 'N/A') {
      if (searchParams.get('department')) {
        department = { department: searchParams.get('department') };
      }
    } else {
      department = { department: user?.department };
    }

    if (user?.course === 'N/A') {
      if (searchParams.get('course')) {
        course = { course: searchParams.get('course') };
      } else {
        course = { course: 'SD2' };
      }
    } else {
      course = { course: user?.course };
    }

    const params = {
      ...department,
      year: new Date().getFullYear(),
      ...course,
      limit: searchParams.get('limit') || 20,
      page: searchParams.get('page') || 1,
    };

    fetchProjects(objectToQueryString(params));
  }, [searchParams, user]);

  const isSpecialJudge = user?.course === 'N/A';

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 0: Space Taken */}
            <Grid item lg={isSpecialJudge ? 3 : 2} sm={0} xs={0} ref={topRef}>
              <div />
            </Grid>

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={isSpecialJudge ? 9 : 9} sm={12} xs={12} ref={topRef}>
              <Paper {...Styles.dashboardPageWidget()}>Projects Bids</Paper>
            </Grid>

            {/*Inner Grid Item 2: Filters */}
            {isSpecialJudge ? (
              <Grid item lg={isSpecialJudge ? 3 : 0} sm={12} xs={12}>
                <Box {...Styles.dashboardContentWidget('auto')}>
                  <Filters
                    filtersSchema={projectsFiltersSchema({
                      avaliableYears: [new Date().getFullYear()],
                    })}
                    searchType={'projects'}
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item lg={2} sm={0} xs={0} ref={topRef}>
                <div />
              </Grid>
            )}

            {/*Inner Grid Item 3: Projects View */}
            <Grid item lg={isSpecialJudge ? 9 : 9} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentMinWidget()}>
                <ProjectsView
                  items={projects}
                  topRef={topRef}
                  link="/judge/dashboard/projects"
                  itemType={'projects'}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function JudgeDashboardProjects() {
  return <Dashboard Page={Projects} />;
}
