import { Check, QuestionAnswer, Scoreboard } from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAuth } from '../../utils/AuthContext';

export default function SearchProject({ project, hideImage = false }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useAuth();
  if (!project) {
    return <></>;
  }
  return (
    <Box padding={1}>
      <Grid
        container
        spacing={3}
        alignItems={{
          lg: 'center',
          md: 'center',
          sm: 'flex-start',
          xs: 'flex-start',
        }}
      >
        {!hideImage && (
          <Grid item lg={5} md={5} sm={0} xs={0}>
            <Box sx={{ display: { lg: 'block', md: 'block', xs: 'none' } }}>
              <img
                src={`${process.env.REACT_APP_SPACES_ENDPOINT_POSTER_IMAGES}${project?.posterImage}`}
                alt={project?.title}
                width={'100%'}
              />
            </Box>
          </Grid>
        )}
        <Grid
          item
          lg={hideImage ? 12 : 7}
          md={hideImage ? 12 : 7}
          sm={hideImage ? 12 : 12}
          xs={hideImage ? 12 : 12}
        >
          <Box height="auto" marginLeft={hideImage ? 0 : 0}>
            <Box
              display="flex"
              flexDirection={'column'}
              margin={{
                lg: hideImage ? 0 : 2,
                md: hideImage ? 0 : 2,
                sm: 0,
                xs: 0,
              }}
              sx={{}}
            >
              <Box display={'block'} gap={1}>
                <Typography
                  variant="body1"
                  fontFamily="serif"
                  fontWeight={'fontWeightBold'}
                  color="black"
                  textOverflow={'ellipsis'}
                  lineHeight={1.2}
                >
                  {
                    project?.title.slice(
                      0,
                      isMobile ? 50 : 100
                    ) /* Limit title length for mobile and desktop */
                  }
                  {project?.title.length > (isMobile ? 50 : 100) && '...'}
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap">
                {project?.ownersDescription.map((owner, index) => (
                  <Typography
                    key={index}
                    variant="subtitle2"
                    fontFamily="sans-serif"
                    color="gray"
                    whiteSpace={'pre'}
                  >
                    {owner?.fullName}
                    {index < project.ownersDescription.length - 1 && '  •  '}
                  </Typography>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap={0.5}
                marginY={1.5}
                sx={{
                  '& .MuiChip-root': {
                    fontSize: '0.75rem', // Adjust text size
                    height: 22, // Reduce height
                    padding: '0 4px', // Adjust padding
                  },
                }}
              >
                {project?.department !== 'ME' && (
                  <Chip
                    label={project?.course}
                    color="default"
                    variant="outlined"
                  />
                )}
                <Chip
                  label={project?.department}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={project?.year}
                  color="default"
                  variant="outlined"
                />
                <Chip
                  label={'Abstract'}
                  color={project?.abstract ? 'success' : 'error'}
                  variant="outlined"
                />
                <Chip
                  label={'Poster PDF'}
                  color={project?.posterLink ? 'success' : 'error'}
                  variant="outlined"
                />
                {project?.projectBids?.includes(user?._id) && (
                  <Chip
                    label={'Bid'}
                    icon={<Check />}
                    color={'success'}
                    variant="outlined"
                  />
                )}
                {!!project?.assignments?.length && user?.role === 'ADMIN' && (
                  <>
                    <Chip
                      label={`${project?.assignments?.length} Assignment${
                        project?.assignments?.length > 1 ? 's' : ''
                      }`}
                      color="success"
                      icon={<Scoreboard />}
                      variant="outlined"
                    />
                  </>
                )}
              </Box>
              <Box display="flex" marginY={1}>
                <QuestionAnswer />
                <Typography
                  variant="subtitle2"
                  fontFamily={'sans-serif'}
                  color="gray"
                  marginLeft={2}
                >
                  {project?.abstract?.slice(0, isMobile ? 100 : 250) ||
                    project?.description?.slice(0, isMobile ? 100 : 250) ||
                    'No Abstract or Description'}
                  {project?.abstract?.length > (isMobile ? 100 : 250) && '...'}
                  {!project?.abstract &&
                    project?.description?.length > (isMobile ? 100 : 250) &&
                    '...'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ margin: 1 }} />
    </Box>
  );
}
