import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StylesForm } from '../../styles/global';
import { useState } from 'react';
import ViewRubric from './ViewRubric';
import { submitScores } from '../../utils/API';
import { useFeedback } from '../../utils/FeedbackContext';
import { Expand } from '@mui/icons-material';

// Scoring Rubric Component
export const ScorableRubric = ({ assignment, flag, setFlag }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is mobile
  const [scores, setScores] = useState(
    assignment?.scores?.length
      ? assignment?.scores.map((score) => String(score))
      : Array(assignment?.rubricCopy?.numOfRubricCategories).fill('')
  );
  const { notifyError, notifySuccess } = useFeedback();
  const handleScoreChange = (index, value) => {
    if (
      value !== '' &&
      (value < 1 || value > assignment?.rubricCopy?.numOfScoreCategories)
    ) {
      return;
    }
    const newScores = [...scores];
    newScores[index] = value;
    setScores(newScores);
  };

  const onSubmit = async () => {
    const scoresNumbers = scores.map((score) =>
      score === '' ? 0 : Number(score)
    );
    console.log(scoresNumbers);
    try {
      await submitScores(assignment._id, { scores: scoresNumbers });
      notifySuccess('Score Submitted Successfully');
      setFlag(!flag);
    } catch (error) {
      console.error('Error Submitting Score:', error);
      notifyError(
        'Error Submitting Score. Please try again. If the problem persists, contact the administrator.'
      );
    }
  };

  const disabled = scores.some((score) => score === '');

  return (
    assignment && (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column', // Stack vertically on small screens
              sm: 'row', // Align horizontally on small screens and up
            },
            gap: 2,
          }}
        >
          {/* Add Assignments */}
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={1}
            flexWrap={'wrap'}
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-between'} // Space between items on larger screens
            width="100%"
          >
            <Box
              alignItems={'center'}
              justifyContent={'center'}
              display={'flex'}
              gap={1}
              flexWrap={'wrap'}
            >
              <Typography {...StylesForm.text('subtitle1')}>
                {assignment?.rubricCopy?.title}
              </Typography>
              <Chip label={assignment?.rubricCopy?.type} size="small" />
              <Chip label={assignment?.rubricCopy?.course} size="small" />
              <Chip label={assignment?.rubricCopy?.department} size="small" />
              <Chip label={assignment?.judgeDetails?.fullName} size="small" />
              <Chip
                label={assignment?.status}
                color={assignment?.status === 'PENDING' ? 'default' : 'success'}
                size="small"
              />
            </Box>
            {assignment?.status === 'PENDING' && (
              <Button color="success" disabled={disabled} onClick={onSubmit}>
                Submit Score
              </Button>
            )}
            {assignment?.status === 'COMPLETED' && (
              <Button color="info" disabled={disabled} onClick={onSubmit}>
                Resubmit Score
              </Button>
            )}
          </Box>
        </Box>

        <Divider sx={{ margin: 2 }} />

        <ViewRubric
          rubric={assignment?.rubricCopy}
          scores={scores}
          handleScoreChange={handleScoreChange}
          scoreText={'Enter Score'}
        />
      </>
    )
  );
};

export default ScorableRubric;
