import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';
import { useEffect, useState } from 'react';
import AdvancedModal from '../../AdvancedModal';
import { sendMessage, updateStation } from '../../../utils/API';
import { useFeedback } from '../../../utils/FeedbackContext';

const SlateBaseText = ({ children }) => {
  return (
    <>
      <Typography {...StylesForm.text('p')} color="darkslategray">
        {children}
      </Typography>
    </>
  );
};

// Widget for Project Details
const InformStation = ({ project, flag, setFlag, adminMode = false }) => {
  const [station, setStation] = useState(
    project?.data?.station || { station: '', building: '', room: '' }
  );
  const [open, setOpen] = useState(false);
  const { notifyError, notifySuccess } = useFeedback();

  useEffect(() => {
    setStation(
      project?.data?.station || { station: '', building: '', room: '' }
    );
  }, [project]);

  const handleSave = async () => {
    try {
      await updateStation(project.data._id, { stationDescription: station });
      await sendMessage(project.data._id, {
        message: 'Your station has been updated for Senior Design Day',
      });
      setFlag(!flag);
      setOpen(false);
      notifySuccess('Station updated successfully');
    } catch (error) {
      console.log(error);
      notifyError('Error updating station');
    }
  };

  return (
    <>
      {project && project?.data && (
        <>
          {/* Title: Project Details */}
          <Box display="flex" justifyContent={'space-between'}>
            <Typography {...StylesForm.text('h6')}>Assigned Station</Typography>
            {adminMode && <Button onClick={() => setOpen(true)}> Edit </Button>}
          </Box>
          <Divider />

          {/* Start of Info: Title, Course, Department, Year, Clients, Instructor */}
          <>
            <Typography {...StylesForm.text('p')}>
              Building:{' '}
              <SlateBaseText>
                <strong>
                  {project.data.station?.building
                    ? `${project.data.station?.building}`
                    : 'Unassigned'}
                </strong>
              </SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Room:{' '}
              <SlateBaseText>
                <strong>
                  {project.data.station?.room
                    ? `${project.data.station?.room}`
                    : 'Unassigned'}
                </strong>
              </SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Station:{' '}
              <SlateBaseText>
                <strong>
                  {project.data.station?.station
                    ? `${project.data.station?.station}`
                    : 'Unassigned'}
                </strong>
              </SlateBaseText>
            </Typography>

            <Typography {...StylesForm.text('p')}>
              Competition Participant Year:{' '}
              <SlateBaseText>
                <strong>{`${project.data.year}`}</strong>
              </SlateBaseText>
            </Typography>

            {project.data.station && (
              <Typography {...StylesForm.text('p')}>
                <i>An adminstrator has assigned a station for your group.</i>{' '}
                See you on Senior Design Day :)
              </Typography>
            )}
          </>
          {/* End of Info */}

          <Divider />
          <AdvancedModal
            open={open}
            title={'Edit Station for Senior Design Day'}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
          >
            <Box display="flex" justifyContent={'space-between'}>
              <TextField
                placeholder="Building"
                value={station.building}
                label="Building"
                onChange={(e) =>
                  setStation({ ...station, building: e.target.value })
                }
              />
              <TextField
                placeholder="Room"
                value={station.room}
                label="Room"
                onChange={(e) =>
                  setStation({ ...station, room: e.target.value })
                }
              />
              <TextField
                placeholder="Station"
                value={station.station}
                label="Station"
                onChange={(e) =>
                  setStation({ ...station, station: e.target.value })
                }
              />
            </Box>
          </AdvancedModal>
        </>
      )}
    </>
  );
};

export default InformStation;
