import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EventIcon from '@mui/icons-material/Event';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { Link } from 'react-router-dom';
import {
  Info,
  PhotoOutlined,
  RequestPage,
  Scoreboard,
} from '@mui/icons-material';

export const mainListItems = (pid) => (
  <React.Fragment>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}`}
    >
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}/invites`}
    >
      <ListItemButton>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Invites" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}/abstract`}
    >
      <ListItemButton>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="Abstract" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}/display-request`}
    >
      <ListItemButton>
        <ListItemIcon>
          <RequestPage />
        </ListItemIcon>
        <ListItemText primary="Display Request" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}/poster`}
    >
      <ListItemButton>
        <ListItemIcon>
          <AddPhotoAlternateIcon />
        </ListItemIcon>
        <ListItemText primary="Poster Template" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}/rubrics`}
    >
      <ListItemButton>
        <ListItemIcon>
          <Scoreboard />
        </ListItemIcon>
        <ListItemText primary="Rubrics" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/student/dashboard/project/${pid}/tutorials`}
    >
      <ListItemButton>
        <ListItemIcon>
          <PhotoOutlined />
        </ListItemIcon>
        <ListItemText primary="Tutorials" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const secondaryListItems = (pid) => (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Atendee Pages
    </ListSubheader>
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
    </Link>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to="/participant-info"
    >
      <ListItemButton>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Participant" />
      </ListItemButton>
    </Link>
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/contact">
      <ListItemButton>
        <ListItemIcon>
          <ContactPageIcon />
        </ListItemIcon>
        <ListItemText primary="Contact" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
