import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Chip } from '@mui/material';
import { Button } from '@mui/material';
import { deleteInvite, getInvites } from '../utils/API';
import { useFeedback } from '../utils/FeedbackContext';
import Clipboard from './Clipboard';

const roleColors = (role) => {
  switch (role) {
    case 'STUDENT':
      return 'primary';
    case 'ADMIN':
      return 'secondary';
    case 'JUDGE':
      return 'warning';
    case 'FACULTY':
      return 'success';
    default:
      return 'default';
  }
};

export default function InvitesTable({ rows, setInvites }) {
  const { notifyError, notifySuccess } = useFeedback();
  const handleClick = async (row) => {
    try {
      await deleteInvite(row?.requestToken);
      const response = await getInvites();
      setInvites(response?.data?.data || []);
      notifySuccess('Invite deleted successfully');
    } catch (error) {
      console.log(error);
      notifyError('Failed to delete invite');
    }
  };
  const determineLink = (row) => {
    if (row?.role === 'STUDENT') {
      return `${window.location.origin}/student/signup/${row?.requestToken}`;
    } else if (row?.role === 'JUDGE') {
      return `${window.location.origin}/judge/signup/${row?.requestToken}`;
    } else if (row?.role === 'ADMIN') {
      return `${window.location.origin}/admin/signup/${row?.requestToken}`;
    } else if (row?.role === 'FACULTY') {
      return `${window.location.origin}/faculty/signup/${row?.requestToken}`;
    }
  };
  return (
    <TableContainer component={Paper} sx={{ p: '20px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Users</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Full Name
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Email
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Department
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Role
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Year
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              Copy Link
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.token}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Avatar alt={row?.fullName} src={row?.avatar} />
              </TableCell>
              <TableCell align="right">
                {row?.email.length > 25
                  ? `${row?.email.substring(0, 25)}...`
                  : row?.email}
              </TableCell>
              <TableCell align="right">
                {row?.department === 'N/A' ? 'CECS' : row?.department}
              </TableCell>
              <TableCell align="right">
                {row?.course === 'N/A' ? 'CECS' : row?.department}
              </TableCell>
              <TableCell align="right">
                <Chip
                  label={row?.role}
                  color={roleColors(row?.role)}
                  sx={{ color: 'white', fontWeight: 'bold' }}
                />
              </TableCell>
              <TableCell align="right">{row?.date}</TableCell>
              <TableCell align="right">
                <Clipboard
                  text={determineLink(row)}
                  onSuccess={() =>
                    notifySuccess(
                      `Copied link to clipboard, ${determineLink(row)}`
                    )
                  }
                  onError={() => notifyError('Failed to copy link')}
                />
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleClick(row)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
