import { useTheme } from '@emotion/react';
import { Close, CloseFullscreen, OpenInBrowser } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  styled,
  Input,
  Chip,
  useMediaQuery,
  Box,
} from '@mui/material';
import { useState } from 'react';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#424242',
    color: '#f5f5f5',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#e0e0e0',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ViewRubric({
  rubric,
  scores,
  handleScoreChange,
  scoreText = 'Score',
}) {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is mobile
  const [expanded, setExpanded] = useState(false); // State to manage expansion of the table'
  const isMobile = !expanded && media; // Allow bypassing mobile check if needed
  return (
    rubric && (
      <TableContainer
        component={Paper}
        color="primary"
        sx={{
          boxShadow: 8,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        <Table sx={{ minWidth: isMobile ? 0 : 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box flexWrap={'wrap'}>
                  {media && (
                    <>
                      {rubric?.scoreCategories?.map((scoreCategory, index) => (
                        <Chip
                          key={index}
                          label={scoreCategory}
                          color="primary"
                          size="small"
                          sx={{
                            margin: 0.5,
                            fontSize: 12,
                          }}
                        />
                      ))}
                      <Chip
                        label={expanded ? 'Collapse Rubric' : 'Expand Rubric'}
                        color="info"
                        size="small"
                        variant="outlined"
                        onClick={() => setExpanded(!expanded)}
                        icon={
                          expanded ? <OpenInBrowser /> : <CloseFullscreen />
                        }
                      />
                    </>
                  )}
                </Box>
              </StyledTableCell>
              {!isMobile && (
                <>
                  <StyledTableCell sx={{ fontWeight: 600 }}>
                    Weights
                  </StyledTableCell>
                  {rubric?.scoreCategories.map((scoreCategory, index) => (
                    <StyledTableCell key={index} sx={{ fontWeight: 600 }}>
                      {scoreCategory}
                    </StyledTableCell>
                  ))}
                </>
              )}
              {scores?.length > 0 && (
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  {scoreText}
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rubric?.rubricCategories.map((rubricCategory, rubricIndex) => (
              <StyledTableRow key={rubricIndex}>
                <StyledTableCell>{rubricCategory}</StyledTableCell>
                {!isMobile && (
                  <>
                    {' '}
                    <StyledTableCell>
                      {rubric.weights[rubricIndex]}
                    </StyledTableCell>
                    {rubric.scoreCategories.map((scoreCategory, scoreIndex) => (
                      <StyledTableCell key={rubricIndex + ' ' + scoreIndex}>
                        {
                          rubric.scoreCategoriesDescriptions[rubricIndex][
                            scoreIndex
                          ]
                        }
                      </StyledTableCell>
                    ))}
                  </>
                )}
                {!handleScoreChange && scores?.length > 0 && (
                  <StyledTableCell>
                    {scores[rubricIndex] !== undefined
                      ? scores[rubricIndex]
                      : 'N/A'}
                  </StyledTableCell>
                )}
                {!!handleScoreChange && scores?.length > 0 && (
                  <StyledTableCell>
                    <Input
                      value={scores[rubricIndex]}
                      type="number"
                      onChange={(e) =>
                        handleScoreChange(rubricIndex, e.target.value)
                      }
                      sx={{
                        width: '50px',
                      }}
                    />
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
