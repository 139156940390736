import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Admin/Dashboard.js';
import { useState, useEffect } from 'react';
import { Styles } from '../../../styles/global.js';
import { getAssignemnts } from '../../../utils/API.js';
import 'chart.js/auto';

import { Chip } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid';
import { MoreVert } from '@mui/icons-material';
import EditModal from '../../../components/Assignments/EditModal.js';
import {
  ModalProvider,
  useModal,
} from '../../../components/Assignments/ModalContext.js';

function getAssignmentTable(assignments) {
  if (!assignments || !assignments?.length) {
    return undefined;
  }
  function determineCompettion(assignment) {
    const competition = assignment.rubricCopy.type;
    if (competition === 'BestInCollege') {
      return 'Best In College';
    }
    if (competition === 'Department') {
      return 'Best In Department';
    }
    if (competition === 'Innovation') {
      return 'Innovation';
    }
    if (competition === 'Other') {
      return 'Other';
    }
    return 'Unknown';
  }
  return assignments?.map((assignment) => {
    return {
      id: assignment._id,
      judge: assignment.judgeDetails.fullName,
      competiton: determineCompettion(assignment),
      project: assignment.projectDetails.title,
      course: assignment.projectDetails.course,
      department: assignment.projectDetails.department,
      status: assignment.status,
      scores: assignment.scores?.join(', ') || '',
      final: assignment.aggregateScore || '',
      contact: assignment.judgeDetails.phoneNumber || '',
      raw: assignment,
    };
  });
}

function ViewAssignemnts({ assignments, setAssignments }) {
  const { setAssignment, openModal } = useModal();
  const paginationModel = { page: 0, pageSize: 10 };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      sortable: true,
      filterable: true,
    },
    {
      field: 'judge',
      headerName: 'Judge',
      width: 100,
      sortable: true,
      filterable: true,
    },
    {
      field: 'contact',
      headerName: 'Contact',
      width: 100,
    },
    {
      field: 'competiton',
      headerName: 'Competition',
      width: 150,
    },
    {
      field: 'project',
      headerName: 'Project',
      width: 130,
    },
    {
      field: 'course',
      headerName: 'Course',
      width: 100,
      type: 'singleSelect',
      valueOptions: ['SD1', 'SD2'],
    },
    {
      field: 'department',
      headerName: 'Dept',
      width: 100,
      type: 'singleSelect',
      valueOptions: ['CIS', 'IMSE', 'ECE', 'ME'],
    },
    {
      field: 'status',
      headerName: 'Completion',
      width: 150,
      renderCell: (params) => {
        const status = params?.row?.status;
        return (
          <Chip
            label={status}
            color={status === 'PENDING' ? 'warning' : 'success'}
          />
        );
      },
      type: 'singleSelect',
      valueOptions: ['PENDING', 'COMPLETED'],
    },
    {
      field: 'scores',
      headerName: 'Scores',
    },
    {
      field: 'final',
      headerName: 'Final',
      width: 70,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      flex: 0,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            alignItems={'center'}
            display="flex"
            height={'100%'}
            width={'100%'}
            justifyContent={'center'}
          >
            <MoreVert
              onClick={() => {
                setAssignment(params?.row?.raw);
                openModal();
              }}
              sx={{
                cursor: 'pointer',
              }}
            />
          </Box>
        );
      },
    },
  ];

  const transformedAssignments = getAssignmentTable(assignments);

  return (
    !!transformedAssignments && (
      <>
        <EditModal />
        <DataGrid
          rows={transformedAssignments}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[10, 25, 50, 100]}
          components={{
            Toolbar: () => (
              <GridToolbar>
                <GridToolbarExport />
              </GridToolbar>
            ),
          }}
          sx={{
            height: 500, // Ensure it has a height
            border: '1px solid #ddd',
            borderRadius: 2,
            overflow: 'hidden',
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '1rem',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-root': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.9rem',
              borderBottom: '1px solid #eee',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: '#f4f4f4',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#e3f2fd',
            },
          }}
          checkboxSelection={false}
          isRowSelectable={false}
          disableRowSelectionOnClick
        />
      </>
    )
  );
}

function Assignments() {
  let [assignments, setAssignments] = useState({});
  useEffect(() => {
    async function fetchData() {
      try {
        const assignments = await getAssignemnts();
        setAssignments(assignments?.data?.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        <Container {...Styles.customContainer('xl')}>
          {/*Outer Grid Container */}
          <Grid container spacing={3}>
            {/*Inner Grid Begins*/}

            {/*Inner Grid Item 1: Page Title */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardPageWidget()}>Judge Assignments</Paper>
            </Grid>

            {/*Inner Grid Item 2:  View Deadline Config */}
            <Grid item lg={12} sm={12} xs={12}>
              <Paper {...Styles.dashboardContentWidget('auto')}>
                <ModalProvider>
                  <ViewAssignemnts
                    assignments={assignments}
                    setAssignments={setAssignments}
                  />
                </ModalProvider>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default function AdminDashboardAssignments() {
  return (
    <Dashboard Page={Assignments} PageName="(A) Dashboard - Judge Assigments" />
  );
}
